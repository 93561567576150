import { HttpRequest } from "@/services/api/http";

export default new (class TagsApi extends HttpRequest {
  constructor() {
    super();
  }

  public async updateTag(tagId: string, data: any) {
    return this.axiosClient.put(`/tags/${tagId}`, data);
  }

  public async getRefreshStatus() {
    return this.axiosClient.get(`/tags/refresh_status/`);
  }

  public async getTagBeaconList() {
    return this.axiosClient.get(`/tags/tag_beacon_list/`);
  }

  public async getBeaconPositions() {
    return this.axiosClient.get(`/tags/beacon_positions/`);
  }

  public async getTagHistory(tagId: number) {
    return this.axiosClient.get(`/tags/tag_beacon_history/${tagId}`);
  }

  public async searchTags(label: string, page: number, pageSize: number) {
    return this.axiosClient.get(
      `/tags/search/?label=${label}&page=${page}&page_size=${pageSize}`
    );
  }

  public async getCSVinformation(
    tagId: number,
    startTime: number,
    endTime: number
  ) {
    return this.axiosClient.get(`/tags/csv/${tagId}/${startTime}/${endTime}`);
  }

  public async getAnchorTagsList(time: number) {
    if (time) return this.axiosClient.get(`/tags/tag_list/?time=${time}`);
    else return this.axiosClient.get(`/tags/tag_list/`);
  }

  public async getAverageTime(startDate: number, endDate: number) {
    if (startDate) {
      if (endDate)
        return this.axiosClient.get(
          `/tags/average_time/?start_date=${startDate}&end_date=${endDate}`
        );
      else
        return this.axiosClient.get(
          `/tags/average_time/?start_date=${startDate}`
        );
    } else return this.axiosClient.get(`/tags/average_time/`);
  }

  public async getInOutTotalUsage(startDate: number, endDate: number) {
    if (startDate) {
      if (endDate)
        return this.axiosClient.get(
          `/tags/in_out_total_usage/?start_date=${startDate}&end_date=${endDate}`
        );
      else
        return this.axiosClient.get(
          `/tags/in_out_total_usage/?start_date=${startDate}`
        );
    } else return this.axiosClient.get(`/tags/in_out_total_usage/`);
  }

  public async getTotalBuzzerTags(startDate: number, endDate: number) {
    if (startDate) {
      if (endDate)
        return this.axiosClient.get(
          `/tags/total_buzzer_tags/?start_date=${startDate}&end_date=${endDate}`
        );
      else
        return this.axiosClient.get(
          `/tags/total_buzzer_tags/?start_date=${startDate}`
        );
    } else return this.axiosClient.get(`/tags/total_buzzer_tags/`);
  }
})();
