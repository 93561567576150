<template>
  <div class="user-beacons">
    <div
      v-if="resources.loading"
      v-loading="true"
      class="user-beacons__loading"
    />
    <div v-else-if="resources.error">
      {{ $t("messages.errorOccurred") }}
    </div>
    <template v-else>
      <BatteryIcon :battery="3" id="hidden-battery" />
      <div class="location-area">
        <h3>
          {{
            isClientDefault
              ? $tc("labels.beacon", 2).toUpperCase()
              : $tc("labels.tag", 2).toUpperCase()
          }}
        </h3>
        <span class="subtitle"
          >{{ $t("labels.quantity") }}: {{ filteredTableData?.length }}</span
        >

        <div class="user-beacons__content">
          <div v-if="$route.params.tab === 'beacons'">
            <LiveMap :beacons-to-show="beaconsToShow" />
          </div>
          <div>
            <div class="table-selected-filters">
              <!-- Search Input -->
              <el-input
                slot="header"
                v-model="search"
                :placeholder="$t('placeholder.search')"
                clearable
                @input="applyFilters()"
              ></el-input>

              <!-- Popover Filter Modal -->
              <el-popover
                placement="left"
                :width="windowWidth > 500 ? 450 : 350"
                trigger="click"
                v-model="showFilterModal"
              >
                <el-form label-position="top">
                  <!-- Last Seen Filter -->
                  <el-form-item :label="$t('labels.lastSeen')">
                    <div style="width: 100%;">
                      <div
                        :style="{
                          maxWidth: dynamicMaxWidth,
                          margin: '10px auto 15px'
                        }"
                      >
                        <el-slider
                          v-model="selectedFilters.lastSeen"
                          range
                          show-stops
                          :marks="marks"
                          :max="120"
                          :min="0"
                          :step="20"
                          :format-tooltip="customTooltip"
                          @change="clearFilters('noBatteryOrOutdated')"
                        >
                        </el-slider>
                      </div>
                    </div>
                  </el-form-item>

                  <!-- Battery Filter -->
                  <el-form-item :label="$t('labels.battery')">
                    <el-checkbox-group v-model="selectedFilters.battery">
                      <el-checkbox
                        :label="0"
                        @change="clearFilters('noBatteryOrOutdated')"
                        >{{ $t("labels.noBattery") }}</el-checkbox
                      >
                      <el-checkbox
                        :label="1"
                        @change="clearFilters('noBatteryOrOutdated')"
                        >{{ $t("labels.low") }}</el-checkbox
                      >
                      <el-checkbox
                        :label="2"
                        @change="clearFilters('noBatteryOrOutdated')"
                        >{{ $t("labels.medium") }}</el-checkbox
                      >
                      <el-checkbox
                        :label="3"
                        @change="clearFilters('noBatteryOrOutdated')"
                        >{{ $t("labels.high") }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </el-form-item>

                  <!-- Active Filter -->
                  <el-form-item :label="$t('labels.installed')">
                    <el-checkbox-group v-model="selectedFilters.active">
                      <el-checkbox
                        :label="true"
                        @change="clearFilters('noBatteryOrOutdated')"
                        >{{ $t("confirmation.yes") }}</el-checkbox
                      >
                      <el-checkbox
                        :label="false"
                        @change="clearFilters('noBatteryOrOutdated')"
                        >{{ $t("confirmation.no") }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </el-form-item>

                  <!-- No Battery OR Outdated AND Installed Filter -->
                  <el-form-item>
                    <span slot="label" style="color: red;">{{
                      $t("labels.maintenanceRequired")
                    }}</span>
                    <el-checkbox
                      v-model="selectedFilters.noBatteryOrOutdated"
                      @change="clearFilters('other')"
                    >
                      {{
                        $t("messages.noBatteryOrOutdated.installedDevicesWith")
                      }}
                      <b>{{ $t("messages.noBatteryOrOutdated.noBattery") }}</b>
                      {{ $t("messages.noBatteryOrOutdated.or") }}
                      <b>{{ $t("messages.noBatteryOrOutdated.noSignal") }}</b>
                      ({{
                        $t("messages.noBatteryOrOutdated.moreThanSevenDays")
                      }}).
                    </el-checkbox>
                  </el-form-item>

                  <!-- Apply or Clear Filter Buttons -->
                  <span>
                    <el-button size="mini" @click="clearFilters('all')">{{
                      $t("button.clearAll")
                    }}</el-button>
                    <el-button
                      size="mini"
                      @click="applyFilters"
                      type="primary"
                      >{{ $t("button.confirm") }}</el-button
                    >
                  </span>
                </el-form>

                <!-- Button Reference that Opens the Popover -->
                <el-button slot="reference" icon="el-icon-s-operation">
                  {{ $t("labels.filter") }}
                </el-button>
              </el-popover>
            </div>

            <!-- Desktop Layout Table -->
            <el-table
              class="full-table"
              @row-click="data => $emit('row-click', data)"
              :data="filteredTableData"
              ref="fullTable"
              height="45vh"
              border
            >
              <!-- Display for when table is empty -->
              <template slot="empty">
                {{ $t("labels.noData") }}
              </template>

              <!-- Tag / Beacon ID Column -->
              <!-- Displays ID for Default Clients and Tag ID for others -->
              <el-table-column
                :resizable="false"
                :label="
                  isClientDefault ? 'ID' : $t('labels.tagId').toUpperCase()
                "
                min-width="90"
              >
                <template slot-scope="scope">
                  <TruncatedTooltip
                    :content="scope.row.tagId.toString(16).toUpperCase()"
                  >
                    {{ scope.row.tagId.toString(16).toUpperCase() }}
                  </TruncatedTooltip>
                </template>
              </el-table-column>

              <!-- Label Column -->
              <el-table-column
                :resizable="false"
                :label="$t('labels.label').toUpperCase()"
                min-width="200"
              >
                <template slot-scope="scope">
                  <TruncatedTooltip :content="scope.row.label">
                    {{ scope.row.label }}
                  </TruncatedTooltip>
                </template>
              </el-table-column>

              <!-- Last Seen Column -->
              <el-table-column
                :resizable="false"
                :label="$t('labels.lastSeen').toUpperCase()"
                min-width="150"
              >
                <template slot-scope="scope">
                  <span
                    class="last-update"
                    v-bind:style="{ color: computedColor(scope.row) }"
                    style="text-align: left"
                    v-if="
                      (isClientDefault && scope.row.active) ||
                        scope.row.lastPosition !== null ||
                        (!isClientDefault && scope.row.lastPosition === null)
                    "
                  >
                    <el-tooltip
                      :disabled="!isTagOutdated(scope.row)"
                      :content="$t('tooltip.outdatedBeacon')"
                      placement="top"
                    >
                      <span>
                        {{
                          moment
                            .tz(
                              scope.row.lastUpdate
                                ? scope.row.lastUpdate * 1000
                                : undefined,
                              clientData.timezone
                            )
                            .format("MMM DD YYYY, HH:mm")
                        }}
                      </span>
                    </el-tooltip>
                  </span>
                  <span v-else>
                    <hr />
                  </span>
                </template>
              </el-table-column>

              <!-- Status Column -->
              <el-table-column
                :resizable="false"
                :label="$t('labels.status').toUpperCase()"
                width="100"
                v-if="isTracker"
              >
                <template slot-scope="scope">
                  {{ scope.row.status }}
                </template>
              </el-table-column>

              <!-- Range Column -->
              <el-table-column
                :resizable="false"
                :label="$t('labels.range').toUpperCase()"
                min-width="90"
              >
                <template slot-scope="scope">
                  {{ -scope.row.threshold - 16 + " / 16" }}
                </template>
              </el-table-column>

              <!-- Battery Column -->
              <!-- Uses batteryIcon to display battery information -->
              <el-table-column
                :resizable="false"
                :label="$t('labels.battery').toUpperCase()"
                min-width="110"
                prop="battery"
                column-key="battery"
              >
                <template slot-scope="scope">
                  <span
                    v-if="
                      (isClientDefault && scope.row.active) ||
                        scope.row.lastPosition != null ||
                        (scope.row.lastPosition == null && !isClientDefault)
                    "
                  >
                    <template v-if="scope.row.battery === 0">
                      <img src="@/assets/battery_dead.svg" alt="no battery" />
                    </template>
                    <template
                      v-else-if="
                        scope.row.battery === 1 ||
                          scope.row.battery === 2 ||
                          scope.row.battery === 3
                      "
                    >
                      <component
                        :is="batteryIcon"
                        :battery="scope.row.battery"
                      />
                    </template>
                    <template v-else>
                      <hr />
                    </template>
                  </span>
                  <span v-else>
                    <hr />
                  </span>
                </template>
              </el-table-column>

              <!-- Installed Column -->
              <el-table-column
                :resizable="false"
                :label="$t('labels.installed').toUpperCase()"
                min-width="120"
                v-if="isClientDefault"
                prop="active"
              >
                <template slot-scope="scope">
                  <template v-if="isClientDefault && scope.row.active">
                    <span>{{ $t("confirmation.yes").toUpperCase() }}</span>
                  </template>
                  <template v-else>
                    <span>{{ $t("confirmation.no").toUpperCase() }}</span>
                  </template>
                </template>
              </el-table-column>

              <!-- Operations -->
              <el-table-column
                :resizable="false"
                :label="$t('statusMessages.inRoute').toUpperCase()"
                width="95"
                v-if="isClientDefaultNonTracker"
              >
                <template slot-scope="scope">
                  <template
                    v-if="isClientDefaultNonTracker && scope.row.inRoute"
                  >
                    <span>{{ $t("confirmation.yes").toUpperCase() }}</span>
                  </template>
                  <template v-else>
                    <span>{{ $t("confirmation.no").toUpperCase() }}</span>
                  </template>
                </template>
              </el-table-column>

              <el-table-column
                :resizable="false"
                :label="$t('labels.operations').toUpperCase()"
                min-width="115"
              >
                <template slot-scope="scope">
                  <div class="edit-buttons">
                    <el-button
                      size="mini"
                      @click="handleEdit(scope.$index, scope.row)"
                      ><i class="el-icon-edit"></i
                    ></el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>

            <!-- Table for Mobile Display -->
            <el-table
              class="expandable-table"
              :data="filteredTableData"
              style="width: 100%"
              height="40vh"
              ref="expandableTable"
              border
            >
              <template slot="empty">
                {{ $t("labels.noData") }}
              </template>

              <el-table-column type="expand">
                <template slot-scope="scope">
                  <p v-if="scope.row.label">
                    <b>ID: </b>{{ scope.row.tagId.toString(16).toUpperCase() }}
                  </p>
                  <p>
                    <b>{{ $t("labels.lastSeen") }}: </b>
                    <span
                      class="last-update"
                      v-bind:style="{ color: computedColor(scope.row) }"
                      style="text-align: left"
                      v-if="
                        (isClientDefault && scope.row.active) ||
                          scope.row.lastPosition != null ||
                          (scope.row.lastPosition == null && !isClientDefault)
                      "
                    >
                      <el-tooltip
                        :disabled="!isTagOutdated(scope.row)"
                        content="This beacon hasn't been seen in over a week."
                        placement="top"
                      >
                        <span>
                          {{
                            moment
                              .tz(
                                scope.row.lastUpdate
                                  ? scope.row.lastUpdate * 1000
                                  : undefined,
                                clientData.timezone
                              )
                              .format("MMM DD YYYY, HH:mm")
                          }}
                        </span>
                      </el-tooltip>
                    </span>
                    <span v-else>
                      -
                    </span>
                  </p>
                  <p v-if="isTracker">
                    <b>{{ $t("labels.status") }}: </b>{{ scope.row.status }}
                  </p>
                  <p>
                    <template v-if="isClientDefault && scope.row.inRoute">
                      <b>{{ $t("statusMessages.inRoute") }}: </b
                      >{{ $t("confirmation.yes").toUpperCase() }}
                    </template>
                    <template v-else>
                      <b>{{ $t("statusMessages.inRoute") }}: </b
                      >{{ $t("confirmation.no").toUpperCase() }}
                    </template>
                  </p>
                  <p>
                    <b>{{ $t("labels.range") }}:</b>
                    {{ -scope.row.threshold - 16 + " / 16" }}
                  </p>
                  <p v-if="isClientDefault && scope.row.active">
                    <b>{{ $t("labels.installed") }}: </b
                    >{{ $t("confirmation.yes").toUpperCase() }}:
                  </p>
                  <p v-else>
                    <b>{{ $t("labels.installed") }}: </b
                    >{{ $t("confirmation.no").toUpperCase() }}
                  </p>
                </template>
              </el-table-column>

              <el-table-column
                :resizable="false"
                :label="
                  isClientDefault
                    ? $tc('labels.beacon', 1).toUpperCase()
                    : $tc('labels.tag', 1).toUpperCase()
                "
                width="120px"
                align="center"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.label
                      ? scope.row.label
                      : scope.row.tagId.toString(16).toUpperCase()
                  }}
                </template>
              </el-table-column>

              <el-table-column
                :resizable="false"
                :label="$t('labels.battery').toUpperCase()"
                align="center"
                prop="battery"
                column-key="battery"
              >
                <template
                  slot-scope="scope"
                  v-if="
                    (isClientDefault && scope.row.active) ||
                      scope.row.lastPosition != null ||
                      (scope.row.lastPosition == null && !isClientDefault)
                  "
                >
                  <template v-if="scope.row.battery === 0">
                    <img src="@/assets/battery_dead.svg" alt="no battery" />
                  </template>
                  <template
                    v-else-if="
                      scope.row.battery === 1 ||
                        scope.row.battery === 2 ||
                        scope.row.battery === 3
                    "
                  >
                    <component :is="batteryIcon" :battery="scope.row.battery" />
                  </template>
                  <template v-else>
                    <hr />
                  </template>
                </template>
                <template v-else>
                  <hr />
                </template>
              </el-table-column>

              <el-table-column
                :resizable="false"
                :label="$t('labels.operations').toUpperCase()"
                align="center"
              >
                <template slot-scope="scope">
                  <div class="edit-buttons">
                    <el-button
                      size="mini"
                      @click="handleEdit(scope.$index, scope.row)"
                      ><i class="el-icon-edit"></i
                    ></el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </template>

    <!-- Refresh Status Button -->
    <div v-if="resources.data.tags.length" class="refresh">
      <el-button type="primary" @click="updateBeacons()">{{
        $t("button.refreshStatus")
      }}</el-button>
    </div>

    <!-- Edit Beacon/Tag Dialog -->
    <EditDialog
      :assetType="isClientDefault ? 'beacon' : 'tag'"
      :assetPrevInfo="tagToEdit"
      :isVisible="editTagDialog.visible"
      :rules="rules"
      @update-edit-dialog-visibility="
        value => {
          editTagDialog.visible = value;
        }
      "
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import * as actionTypes from "@/store/action-types";
import tagsApi from "@/modules/tags/api/tags.api";
import moment from "moment-timezone";
import BatteryIcon from "@/components/icons/BatteryIcon.vue";
import EditDialog from "@/components/EditDialog.vue";
import TruncatedTooltip from "@/components/TruncatedTooltip.vue";
import LiveMap from "@/views/LiveMap.vue";
import { cloneDeep } from "lodash";

export default {
  components: {
    BatteryIcon,
    EditDialog,
    TruncatedTooltip,
    LiveMap
  },
  data() {
    return {
      certifyEditTagDialogSave: false,
      childTagId: null,
      editAssetDialog: {
        visible: false,
        loading: false
      },
      editTagDialog: {
        visible: false,
        loading: false
      },
      finalTags: [],
      inactiveTags: [],
      moment,
      orphanTags: [],
      rules: {
        label: [
          {
            max: 45,
            message: this.$t("messages.labelMaxLength"),
            trigger: "blur"
          }
        ]
      },
      tagsSelected: [],
      tagsToDelete: [],
      tagsToSend: [],
      tagToEdit: {},
      tagToReplace: null,
      tempOrphanTags: [],
      search: "",
      statusOptions: ["NO STATUS", "NOK", "OK"],
      selectedFilters: {
        lastSeen: [120, 120],
        active: [],
        battery: [],
        noBatteryOrOutdated: false
      },
      showFilterModal: false,
      filteredTableData: [],
      marks: {
        0: "<",
        20: this.$t("labels.threeMonths"),
        40: this.$t("labels.oneMonth"),
        60: this.$t("labels.twoWeeksNumber"),
        80: this.$t("labels.oneWeek"),
        100: this.$t("labels.today"),
        120: this.$t("labels.all")
      }
    };
  },
  props: {
    showOperations: {
      type: Boolean,
      default: true
    },
    showActiveRoute: {
      type: Boolean,
      default: false
    },
    batteryIcon: {
      type: Object,
      required: true
    },
    windowWidth: {
      type: Number,
      default: 769
    }
  },

  computed: {
    ...mapState("user", {
      resources: "resources"
    }),
    ...mapState("clients", {
      clientData: "data",
      clientAnchors: "anchors"
    }),
    ...mapGetters("auth", [
      "isAdmin",
      "isTracker",
      "isClientDefaultNonTracker"
    ]),
    ...mapGetters("clients", ["isClientDefault"]),
    filteredDataBySearch() {
      const trimmedSearch = this.search?.trim();

      const matchesSearch = tag =>
        !trimmedSearch ||
        tag.tagId
          .toString(16)
          .toLowerCase()
          .trim()
          .includes(trimmedSearch.toLowerCase()) ||
        tag.label
          ?.trim()
          .toLowerCase()
          .includes(trimmedSearch.toLowerCase());

      return this.resources.data.tags.filter(tag =>
        this.isTracker
          ? tag.type === 0 && matchesSearch(tag)
          : matchesSearch(tag)
      );
    },

    beaconsToShow() {
      return this.filteredTableData.length
        ? this.filteredTableData.map(beacon => beacon.id)
        : [];
    },
    dynamicMaxWidth() {
      return this.windowWidth > 500 ? "420px" : "320px";
    }
  },

  methods: {
    ...mapActions("user", {
      userSetResourcesTags: actionTypes.USER_SET_RESOURCES_TAGS
    }),
    computedColor(tag) {
      if (this.isTagOutdated(tag)) return "red";
      else return "";
    },
    filterTag(value, row) {
      return row.tag === value;
    },
    filterHandler(value, row, column) {
      const property = column["property"];
      return row[property] === value;
    },
    isTagOutdated(tag) {
      const oneWeekAgo = Math.ceil(
        moment()
          .tz(this.clientData.timezone)
          .subtract(7, "days")
          .valueOf() / 1000
      );
      if (tag.lastUpdate < oneWeekAgo) return true;
      return false;
    },
    updateChildTags(tagList) {
      const updatedTagList = tagList;
      updatedTagList.forEach(elem => {
        elem.childTags = [];
      });
      updatedTagList.forEach(element => {
        const index = updatedTagList.findIndex(
          elem => elem.id == element.parentId
        );
        if (index !== -1) {
          updatedTagList[index].childTags.push({
            id: element.tagId,
            label: element.label,
            parentId: element.parentId
          });
        }
      });

      this.userSetResourcesTags(updatedTagList);
    },

    async updateBeacons() {
      try {
        const { data } = await tagsApi.getRefreshStatus();
        const updatedTagList = this.updateChildTags(data);
        this.orderTags(updatedTagList);
        this.applyFilters();
      } catch (_) {
        // DO nothing
      }
    },

    thresholdToRange(threshold) {
      return threshold >= -16 ? 0 : -threshold - 16;
    },

    handleEdit(_, row) {
      this.tagToEdit = row;
      this.editTagDialog.visible = true;
    },
    sortByLastUpdate(arr) {
      arr.sort((a, b) => {
        if (a.lastUpdate < b.lastUpdate) return 1;
        else return -1;
      });
    },

    orderTags(updatedTagList) {
      const noBatteryTags = updatedTagList.filter(
        tag => tag.battery === 0 && tag.active
      );
      this.sortByLastUpdate(noBatteryTags);

      const tagsNotSeen = updatedTagList.filter(
        tag => this.isTagOutdated(tag) && tag.battery != 0 && tag.active
      );
      this.sortByLastUpdate(tagsNotSeen);

      const badTags = [].concat(noBatteryTags).concat(tagsNotSeen);

      const restOfTags = updatedTagList.filter(
        tag => tag.battery != 0 && badTags.indexOf(tag) === -1 && tag.active
      );
      this.sortByLastUpdate(restOfTags);

      const notActiveTags = updatedTagList.filter(tag => !tag.active);
      this.sortByLastUpdate(notActiveTags);

      const sortedTagList = badTags.concat(restOfTags).concat(notActiveTags);

      this.userSetResourcesTags(sortedTagList);
    },

    filterLastSeen(range, row) {
      if (!row.lastUpdate) return false; // Return false if there's no last update
      if (range[0] === 120 || range[1] === 120) return true; // Handle no filters applied

      const lastUpdateDate = moment.tz(
        row.lastUpdate * 1000,
        this.clientData.timezone
      );
      const isAllDataVisible =
        (this.isClientDefault && row.active) ||
        row.lastPosition !== null ||
        (!this.isClientDefault && row.lastPosition === null);

      // Date range mapping
      const dateRanges = {
        0: moment()
          .startOf("day")
          .subtract(91, "days"), // 3 months and one day
        20: moment()
          .startOf("day")
          .subtract(90, "days"), // 3 months ago
        40: moment()
          .startOf("day")
          .subtract(30, "days"), // 1 month ago
        60: moment()
          .startOf("day")
          .subtract(14, "days"), // 14 days ago
        80: moment()
          .startOf("day")
          .subtract(7, "days"), // 7 days ago
        100: moment().startOf("day") // Today
      };

      const minKey = Math.min(...range);
      const maxKey = Math.max(...range);
      const startDate = dateRanges[minKey]; // Older date
      const endDate = dateRanges[maxKey]; // More recent date

      if (!startDate || !endDate) return false; // Handle undefined values

      // If minKey is 0, include everything older than maxKey
      if (minKey === 0) {
        return (
          lastUpdateDate.isSameOrBefore(dateRanges[maxKey], "day") &&
          isAllDataVisible
        );
      }

      // Standard filtering logic: Show only records within the range
      return (
        lastUpdateDate.isSameOrAfter(startDate, "day") &&
        lastUpdateDate.isSameOrBefore(endDate, "day") &&
        isAllDataVisible
      );
    },

    showNoBatteryOrOutated() {
      this.filteredTableData = this.filteredDataBySearch.filter(tag => {
        const batteryMatch = tag.battery === 0;
        const activeMatch = tag.active === true;
        const lastSeenMatch = this.isTagOutdated(tag);
        const isAllDataVisible =
          (this.isClientDefault && tag.active) ||
          tag.lastPosition != null ||
          (!this.isClientDefault && tag.lastPosition == null);

        return (
          (batteryMatch || lastSeenMatch) && activeMatch && isAllDataVisible
        );
      });
    },

    applyFilters() {
      const {
        battery,
        active,
        lastSeen,
        noBatteryOrOutdated
      } = this.selectedFilters;

      if (noBatteryOrOutdated) {
        this.showNoBatteryOrOutated();
      } else {
        const isBatteryFiltered = battery?.length > 0;
        const isLastSeenFiltered = lastSeen?.length > 0;
        const isActiveFiltered = active?.length > 0;

        this.filteredTableData = this.filteredDataBySearch.filter(tag => {
          const batteryMatch = isBatteryFiltered
            ? battery.includes(tag.battery)
            : true;
          const activeMatch = isActiveFiltered
            ? active.includes(tag.active)
            : true;
          const lastSeenMatch = isLastSeenFiltered
            ? this.filterLastSeen(lastSeen, tag)
            : true;
          const isAllDataVisible =
            (this.isClientDefault && tag.active) ||
            tag.lastPosition != null ||
            (!this.isClientDefault && tag.lastPosition == null);

          if (isBatteryFiltered) {
            return isAllDataVisible
              ? batteryMatch && activeMatch && lastSeenMatch
              : false;
          } else if (!isBatteryFiltered) {
            return activeMatch && lastSeenMatch;
          } else {
            return true;
          }
        });
      }

      this.showFilterModal = false;
    },

    clearFilters(filterType = "all") {
      // Handle clearing based on the filterType argument
      switch (filterType) {
        case "all":
          // Reset all filters to default values
          this.selectedFilters = {
            lastSeen: [120, 120],
            active: [],
            battery: [],
            noBatteryOrOutdated: false
          };
          this.filteredTableData = [...this.filteredDataBySearch];
          this.showFilterModal = false;
          break;

        case "noBatteryOrOutdated":
          // Specifically clear the 'noBatteryOrOutdated' filter
          this.selectedFilters.noBatteryOrOutdated = false;
          break;

        case "other":
          // Clear only the 'other' related filters when 'noBatteryOrOutdated' is true
          if (this.selectedFilters.noBatteryOrOutdated) {
            this.selectedFilters.lastSeen = [120, 120];
            this.selectedFilters.battery = [];
            this.selectedFilters.active = [];
          }
          break;

        default:
          console.warn("Unknown filter type:", filterType);
          break;
      }
    },
    customTooltip(value) {
      let dateText = "";

      if (value === 120) {
        return this.marks[120];
      } else if (value === 100) {
        dateText = moment()
          .startOf("day")
          .format("LL"); // Show today's date
      } else if (value === 80) {
        dateText = moment()
          .startOf("day")
          .subtract(7, "days")
          .format("LL"); // 1 week ago
      } else if (value === 60) {
        dateText = moment()
          .startOf("day")
          .subtract(14, "days")
          .format("LL"); // 2 weeks ago
      } else if (value === 40) {
        dateText = moment()
          .startOf("day")
          .subtract(1, "months")
          .format("LL"); // 1 month ago
      } else if (value === 20) {
        dateText = moment()
          .startOf("day")
          .subtract(3, "months")
          .format("LL"); // 3 months ago
      } else if (value === 0) {
        dateText = `< ${moment()
          .startOf("day")
          .subtract(91, "days")
          .format("LL")}`; // More than 3 months ago
      }

      return dateText;
    }
  },

  watch: {
    "$route.params.tab": {
      handler(newValue) {
        if (newValue !== "beacons") {
          this.editTagDialog.visible = false;
        }
      }
    },
    "$i18n.locale": {
      handler() {
        this.marks = {
          0: "<",
          20: this.$t("labels.threeMonths"),
          40: this.$t("labels.oneMonth"),
          60: this.$t("labels.twoWeeksNumber"),
          80: this.$t("labels.oneWeek"),
          100: this.$t("labels.today"),
          120: this.$t("labels.all")
        };
      }
    }
  },

  async created() {
    this.isLoading = true;
    this.orderTags(this.resources.data.tags);
    this.filteredTableData = cloneDeep(this.filteredDataBySearch);
    this.clearFilters();
    this.isLoading = false;
  }
};
</script>

<style lang="scss">
.user-beacons {
  padding: 24px 0;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .el-dialog {
    width: 920px !important;

    @media (max-width: $md) {
      width: 95% !important;
      display: flex;
      flex-direction: column;
    }
  }

  .checkbox-installed-options {
    display: none;
    @media (max-width: 1120px) {
      display: block;
    }
  }

  .dialog-info {
    margin-bottom: 10px;
  }

  .location-area {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 100%;
  }

  .subtitle {
    padding-top: 10px;
    padding-bottom: 1.5rem;
  }

  .asset-area {
    width: 1024px;
    margin-top: 2rem;

    h3 {
      margin-bottom: 1.5rem;
    }
  }

  .range-level {
    margin-top: 8%;
  }

  #hidden-battery {
    position: absolute;
    top: -500px;
    z-index: -1;
  }

  .background-red {
    background-color: "red";
    height: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
  }

  .el-table {
    overflow-y: auto;
  }

  .el-table.full-table {
    @media (max-width: 1120px) {
      display: none;
    }
  }

  .low-battery {
    margin-bottom: 1%;
  }

  .tags-not-seen {
    margin-top: 3%;
    margin-bottom: 1%;
  }

  .refresh {
    margin-top: 2%;
  }

  .el-table.expandable-table {
    display: none;

    @media (max-width: 1120px) {
      display: block;
    }

    .el-table__expanded-cell {
      padding: 20px 40px 24px;
    }

    .expandable-table__signal {
      .wifi {
        top: 24px;
        display: inline-block;
      }

      .wifi__no-connection {
        top: 26px;
        left: 0px;
      }
    }

    .expandable-table__id,
    .expandable-table__ssid {
      margin-top: 24px;
    }

    .user-beacons__power-source {
      text-align: left;

      &::before {
        left: 72px;
        bottom: -18px;
        z-index: 1;
      }

      img {
        position: relative;
        top: 16px;
      }
    }

    .expandable-table__last-seen {
      margin-top: 26px;
    }

    .expandable-table__online-since {
      margin-top: 12px;
    }
  }

  .cell span {
    display: block;
    text-align: center;

    i {
      font-size: 24px;
    }
  }

  .cell .edit-buttons {
    display: flex;
    justify-content: center;
    i {
      font-size: 15px;
    }
  }

  &__power-source--on {
    position: relative;
    text-align: center;

    &::before {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      width: 24px;
      height: 24px;
      background-color: white;
      border-radius: 50%;
      background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAACy0lEQVRIS62VT0hUURTGv3NfERqtJPpDkKkE6ZgzU9Amg2rTSpzpD6YWtIiiTMfEsNoWkYTOTC1q0SIq1LSZqaBFhQsXUkSNWUZQYSU1gumi1Epn7on3hhnfPN/om+zu3jvnfL/vnXvufYQ51qagP5+ZK2LAdgguAnNOPF18B+MtCe6WQmnrLzsxmE6GzAKbu1rypklpFpBuCGGak6yTkklRuiTHTvW56j8Z9WYVO0Leg5LpqgCy5vo6Y0xKOakIOvLS5bmlj6UAHAFvE4guZCJszGVCY1953aXE+yRAdQ6mGwsRT9QyU3Wfu/a2+qwBtJ4ryptM25LWjMQEoBSFd9d81gCOgK8ThD3/w/2MBnWEXbUVpI6ilLH3805LGrrq0JVrw8Ohd/gdi85kSckskEfOgO8sE879i3tVvKF4Gyrz7Xg2MoT6pw9SIcynqSTgeyIIOzMF6MXV2onoFA71dOLDj1G91GMqCXkjgmmlEZCzJBujfyZNuWbix3pDeD02nJrP+EolwdYpAbFYH6nKd+Dohi2o6b2HV2ORlCLL4vGqqVkAR85qXC+ND9RkdBrHe0NJiCp+srgUqoFEW0ydz1hSAb5vAlilP3ke21YcKHCmQPrHIqi3laK6wLI4oLXIZJNVp0ZIz/Agdq1Zb9W5lkeQj8ge8p4hpvPG3TRCEnF1WuZpi+6sURNtvH95nTId/Wh20IyQjMQBCankaVeFM+S/w8x7zWYyAXHn2qw714S4LezyVGoAe7A1lyUGhBDZ6SArspZh+NdPi+dRji8iWfi8vGEoeV07g95qBt20qDB3GmN/2F3XHt9o3bIH/I1E3LwgCKMh7K5r0Y99ip494K8i5msQWJoZSI6DxeGE87QANeC4e2UthLwIGdtn4RqXAHcIBU0vyjxfzMY9rVF184mpAkQ7wCgEYXl8QDBCJAeYlG7ERLv650on8hf6Czzo4QTILgAAAABJRU5ErkJggg==");
    }
  }

  &__power-source--off {
    position: relative;
    text-align: center;

    &::before {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      width: 24px;
      height: 24px;
      background-color: white;
      border-radius: 50%;
      background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAC9UlEQVRIS62Wy08TURTGv3unmKjLtgkaE23ZwRJ6qbhSN7SUR4waVOICIv4BBlNeLRSN0RjXsoDEqBHUNU/DTmCmshM3hvpAYSF1hcagc6+5E2YyHWcGEWfXOWe+37nndUvg87yqrq7QFaWVAycBVAEISncBbBDgDQXmADyp1bR3XjLEzZCvqYkKQu7ohJyhgKuP+R0HBAWec0qvH19cfO/U++NjlbHL4Pw+KN3vdzqnjQPfFSGusnz+kd1WAtBisbQg5NZuhJ2+gpCuuKreNd9bACNy4MFexC1RIdpYPv9Y/jYAMucceG1Py+GODmytr2NjYsKXGW5pQVkwiLWREcuPA994IFB1Yn7+gwFQGXsG4KzpIcWPdHYCnKMwNOQJCTc3I9LdDRCCT8PDWBsdtSCCkPG4qrYS2Yo/FeWtvVtCySSi/f0ApZ6QcGMjIr29hrjgHCuZDL7OztpPIUBplKiM9QK44cxDqKEB0b4+V0g4lUJE2jzEbbXoJguMvaDAabdEh1IpRGWUtpNIURMsIy9kMijaIi9pUSFmJWCdAuVelTSitUEMP0qNtBSyWRRnZrybQNc/S8AWBcr8WsWeb2NVSPGBARSnp307jANb/w4YHERxampnwDxjawpwyDNFTU2I9PRYBTWGx0zRTpDtFHkW2d7nZlpkkSuyWavwK7kcipOT7vFxPiPbtAfATaeHnNBIOm1Fbi9osL6+BFLI5bDhDklLQIQDK/ZB8xM3AwkmEqjIZDyHkcsRDQSi5qp4CuCcc1Xs1OehRALRbYhzVWxfRBcNwEI8fgycL1PggAVpb8eP1dWS8XcdxmQS+8rLS/YQgE0iRCXL51etda3FYm2CkIf/Y10LQi7EVXXMWtemqMpYF4A7e4Rcq9W0e9Y+coppsdglnZBhChzcJWhTEHLFjNwTIA0v6+qOKrp+Wwhx/i8ufU6BcUXX0zVLSx+dQfn+Y5DFV3S9FcApwXklV5Swset0/QsIWQalc78CgTF5c3md9jeDjGiF9sPCPQAAAABJRU5ErkJggg==");
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 30% 68%;
    grid-gap: 10px;
    justify-content: center;

    @media (max-width: 1120px) {
      grid-template-columns: 100%;
    }

    .table-selected-filters {
      display: flex;
      align-items: center;
      gap: 6px;
      margin-bottom: 10px;
    }
  }
  .el-popover__reference-wrapper {
    .el-button {
      display: flex;
      align-items: center;
    }
  }

  .dashboard {
    padding-top: 0;

    @media (max-width: 1024px) {
      padding-top: 0;
    }

    &__map.leaflet-container {
      height: 50vh;

      @media (max-width: 1120px) {
        height: 40vh;
      }
    }

    &__fit-to-bounds-settings {
      position: absolute;
      top: 85px;

      @media (max-width: 470px) {
        top: 85px;
      }
    }

    &__tag-slider-settings {
      top: 130px;
      @media (max-width: 768px) {
        top: 130px;
      }
    }
    .leaflet-control-zoom.leaflet-bar.leaflet-control {
      @media (max-width: 470px) {
        top: 0;
      }
    }
  }
}
.el-slider__marks-text {
  color: #606266 !important;
  word-break: break-word !important;
  text-align: center;
  width: max-content;

  @media (max-width: 500px) {
    line-height: 1.5;
    width: 60px;
  }
}
</style>
