<template>
  <div class="usage-graphs">
    <div class="graph-wrapper">
      <h2>{{ $t("analytics.averageUsageWeek") }}</h2>
      <LineChart
        :datasets="lineChartDataByWeekday.datasets"
        :labels="lineChartDataByWeekday.labels"
        :x-label="$t('analytics.weekday')"
      />
    </div>
    <div class="graph-wrapper">
      <h2>{{ $t("analytics.averageUsageHour") }}</h2>
      <LineChart
        :datasets="lineChartDataByHour.datasets"
        :labels="lineChartDataByHour.labels"
        :x-label="$t('analytics.hourInterval')"
      />
    </div>
    <div class="graph-wrapper">
      <h2>{{ $t("analytics.usagePercentage") }}</h2>
      <PieChart
        :data="pieChartData.data"
        :labels="pieChartData.labels"
        :colors="pieChartData.colors"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import PieChart from "@/components/stats/components/PieChart.vue";
import LineChart from "@/components/stats/components/LineChart.vue";

export default {
  name: "UsageGraphs",
  components: {
    LineChart,
    PieChart
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    selectedAnchorIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      colorMapping: {},
      colorOptions: [
        "#1F4258",
        "#2FA4A1",
        "#A0D2DD",
        "#F2D890",
        "#B5877F",
        "#CD5332"
      ]
    };
  },
  computed: {
    ...mapState("clients", {
      anchors: "anchors"
    }),
    lineChartDataByWeekday() {
      const weekdayGroups = {};

      this.data.forEach(dataInfo => {
        // Determine the weekday (1 = Monday, …, 7 = Sunday)
        const weekdayKey = moment.utc(dataInfo.timestamp * 1000).isoWeekday();
        // Create a unique day identifier, e.g. "2025-02-12"
        const dayId = moment
          .utc(dataInfo.timestamp * 1000)
          .format("YYYY-MM-DD");

        // Create the group for this anchor if it doesn't exist yet
        if (!weekdayGroups[dataInfo.anchorId]) {
          let anchorLabel = "";
          const anchorInfo = this.anchors.data.find(
            anchor => anchor.id === dataInfo.anchorId
          );
          if (anchorInfo) {
            anchorLabel = anchorInfo.label;
          }
          weekdayGroups[dataInfo.anchorId] = {
            label: anchorLabel,
            data: {},
            // Use an object to hold Sets of unique day IDs per weekday
            count: {},
            color: this.colorMapping[dataInfo.anchorId] || "#000000"
          };
        }

        // If this weekday hasn't been seen for this anchor, initialize it
        if (!weekdayGroups[dataInfo.anchorId].data[weekdayKey]) {
          weekdayGroups[dataInfo.anchorId].data[weekdayKey] = 0;
          // Initialize a Set to hold unique day identifiers
          weekdayGroups[dataInfo.anchorId].count[weekdayKey] = new Set();
        }

        // Add the motion value
        weekdayGroups[dataInfo.anchorId].data[weekdayKey] +=
          dataInfo.totalSMotion;
        // Add the day identifier to the Set so it is only counted once per day
        weekdayGroups[dataInfo.anchorId].count[weekdayKey].add(dayId);
      });

      // Weekday ordering & labels
      const allWeekdaysNumbers = [1, 2, 3, 4, 5, 6, 7];
      const allWeekdays = [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday"
      ];
      const allWeekdaysLocalized = allWeekdays.map(day =>
        this.$t(`weekdays.${day}`)
      );

      // Make sure every selected anchor has data (even if zero)
      this.selectedAnchorIds.forEach(anchorId => {
        // Note: Adjust the property name if needed (using either anchorId or id)
        const anchorInfo = this.anchors.data.find(
          anchor => anchor.anchorId === anchorId
        );
        if (anchorInfo && !weekdayGroups[anchorInfo.id]) {
          weekdayGroups[anchorInfo.id] = {
            label: anchorInfo.label || "",
            data: {},
            count: {},
            color: this.colorMapping[anchorInfo.id] || "#000000"
          };
          // Initialize each weekday with 0 and an empty Set for the count
          allWeekdaysNumbers.forEach(day => {
            weekdayGroups[anchorInfo.id].data[day] = 0;
            weekdayGroups[anchorInfo.id].count[day] = new Set();
          });
        }
      });

      // Build the datasets, calculating the average by dividing total motion by the count of unique days
      const datasets = Object.values(weekdayGroups)
        .sort((a, b) => a.label.localeCompare(b.label))
        .map(anchor => {
          return {
            label: anchor.label,
            borderColor: anchor.color || "#1F4258",
            pointBackgroundColor: anchor.color || "#1F4258",
            fill: false,
            data: allWeekdaysNumbers.map(day => {
              const total = anchor.data[day] || 0;
              // Use the size of the Set to know how many unique days contributed data.
              // If there is no data, default to 1 to avoid division by zero.
              const count =
                (anchor.count[day] && anchor.count[day].size) * 3600 || 1;
              return total / count;
            })
          };
        });

      return {
        labels: allWeekdaysLocalized,
        datasets
      };
    },
    lineChartDataByHour() {
      const anchorGroups = {};
      this.data.forEach(dataInfo => {
        const hour = moment.utc(dataInfo.timestamp * 1000).hours();
        const formattedHourInterval = `${String(hour).padStart(
          2,
          "0"
        )}:00 - ${String(hour + 1).padStart(2, "0")}:00`;

        if (!anchorGroups[dataInfo.anchorId]) {
          let anchorLabel = "";
          const anchorInfo = this.anchors.data.find(
            anchor => anchor.id === dataInfo.anchorId
          );
          if (anchorInfo) {
            anchorLabel = anchorInfo.label;
          }
          anchorGroups[dataInfo.anchorId] = {
            label: anchorLabel,
            data: {},
            count: {},
            color: this.colorMapping[dataInfo.anchorId] || "#000000"
          };
        }
        if (!anchorGroups[dataInfo.anchorId].data[formattedHourInterval]) {
          anchorGroups[dataInfo.anchorId].data[formattedHourInterval] = 0;
          anchorGroups[dataInfo.anchorId].count[formattedHourInterval] = 0;
        }
        anchorGroups[dataInfo.anchorId].data[formattedHourInterval] +=
          dataInfo.totalSMotion;
        anchorGroups[dataInfo.anchorId].count[formattedHourInterval]++;
      });

      const allLabelsKeys = [
        ...new Set(
          this.data.map(dataInfo => {
            const hour = moment.utc(dataInfo.timestamp * 1000).hours();
            return `${String(hour).padStart(2, "0")}:00 - ${String(
              hour + 1
            ).padStart(2, "0")}:00`;
          })
        )
      ].sort();

      this.selectedAnchorIds.forEach(anchorId => {
        const anchorInfo = this.anchors.data.find(
          anchor => anchor.anchorId === anchorId
        );
        if (anchorInfo && !anchorGroups[anchorInfo.id]) {
          anchorGroups[anchorInfo.id] = {
            label: anchorInfo.label || "",
            data: {},
            count: {},
            color: this.colorMapping[anchorInfo.id] || "#000000"
          };
          allLabelsKeys.forEach(label => {
            anchorGroups[anchorInfo.id].data[label] = 0;
            anchorGroups[anchorInfo.id].count[label] = 1;
          });
        }
      });

      const datasets = Object.values(anchorGroups)
        .sort((a, b) => a.label.localeCompare(b.label))
        .map(anchor => {
          return {
            label: anchor.label,
            borderColor: anchor.color || "#1F4258",
            pointBackgroundColor: anchor.color || "#1F4258",
            fill: false,
            data: allLabelsKeys.map(label => {
              const total = anchor.data[label] || 0;
              const count = anchor.count[label] || 1;
              return total / count;
            })
          };
        });

      return {
        labels: allLabelsKeys,
        datasets
      };
    },
    pieChartData() {
      const anchorGroups = {};
      this.data.forEach(dataInfo => {
        if (!anchorGroups[dataInfo.anchorId]) {
          let anchorLabel = "";
          const anchorInfo = this.anchors.data.find(
            anchor => anchor.id === dataInfo.anchorId
          );
          if (anchorInfo) {
            anchorLabel = anchorInfo.label;
          }
          anchorGroups[dataInfo.anchorId] = {
            totalTs: 0,
            label: anchorLabel,
            color: this.colorMapping[dataInfo.anchorId] || "#000000"
          };
        }
        anchorGroups[dataInfo.anchorId].totalTs += dataInfo.totalSMotion;
      });

      this.selectedAnchorIds.forEach(anchorId => {
        const anchorInfo = this.anchors.data.find(
          anchor => anchor.anchorId === anchorId
        );
        if (anchorInfo && !anchorGroups[anchorInfo.id]) {
          anchorGroups[anchorInfo.id] = {
            totalTs: 0,
            label: anchorInfo.label || "",
            color: this.colorMapping[anchorInfo.id] || "#000000"
          };
        }
      });

      const sortedAnchors = Object.values(anchorGroups).sort((a, b) =>
        a.label.localeCompare(b.label)
      );
      return {
        labels: Object.values(sortedAnchors).map(d => d.label),
        data: Object.values(sortedAnchors).map(d => d.totalTs),
        colors: Object.values(sortedAnchors).map(d => d.color)
      };
    }
  },
  created() {
    this.anchors.data.forEach((anchor, index) => {
      this.colorMapping[anchor.id] = this.colorOptions[index];
    });
  }
};
</script>

<style lang="scss">
.usage-graphs {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px 0;

  .graph-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    canvas {
      max-width: 800px;
      width: 800px !important;
      margin-top: 12px;
      padding: 24px;
      background: #ffffff;
      border: 1px solid #dcdfe6;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
      max-height: 400px;
      border-radius: 4px;

      @media (max-width: 915px) {
        width: 90vw !important;
        padding: 24px 10px;
      }
    }
  }
}
</style>
