<template>
  <div class="user-ssids">
    <div v-if="ssids.loading" v-loading="true" class="user-ssids__loading" />
    <div v-else-if="ssids.error">
      {{ $t("messages.errorOccurred") }}
    </div>
    <template v-else>
      <el-button
        v-if="isAdmin"
        class="user-ssids__new"
        type="primary"
        @click="createNewSsid()"
        >{{ $t("button.newWirelessNetwork") }}
      </el-button>
      <div v-if="!ssids.data.length">
        {{ $t("messages.noWireless") }}
      </div>
      <div
        v-else
        :class="[
          'user-ssids__container',
          isAdmin ? 'user-ssids__container--admin' : ''
        ]"
      >
        <el-table
          border
          :data="sortedSsids"
          :row-class-name="tableRowClassName"
        >
          <template slot="empty">
            {{ $t("labels.noData") }}
          </template>
          <el-table-column :resizable="false" label="SSID" width="220">
            <template slot-scope="scope">
              {{ scope.row.ssid }}
            </template>
          </el-table-column>
          <el-table-column
            :resizable="false"
            :label="$t('formFields.password')"
            width="200"
          >
            <template slot-scope="scope">
              <div class="password-view">
                <span v-if="showPassword && scope.$index === 0">
                  {{ ssidPassword }}
                </span>
                <span v-else>
                  &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
                </span>
                <span
                  v-if="scope.$index === 0 && isAdmin && scope.row.internal"
                  class="password-view__eye"
                  @click.stop="showPassword = !showPassword"
                >
                  <i class="el-icon-view"></i>
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="isAdmin"
            :resizable="false"
            :label="$t('labels.operations')"
            width="200"
          >
            <template slot-scope="scope">
              <div class="button-column">
                <el-button
                  size="mini"
                  @click="handleEdit(scope.$index, scope.row)"
                  :disabled="scope.row.internal"
                  ><i class="el-icon-edit"></i
                ></el-button>
                <el-button
                  v-if="ssids.data.length > 1"
                  size="mini"
                  type="danger"
                  :disabled="scope.row.internal"
                  @click="handleDelete(scope.$index, scope.row)"
                  ><i class="el-icon-delete"></i
                ></el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </template>
    <!-- Delete ssid dialog -->
    <el-dialog
      v-if="ssidToDelete"
      :title="$t('dialogs.deleteWirelessNetwork.title')"
      :visible.sync="deleteSsidDialog.visible"
      width="300px"
    >
      <span class="warning"
        >{{ $t("dialogs.deleteWirelessNetwork.line1") }}: "{{
          ssidToDelete.ssid
        }}"?</span
      >
      <div v-if="ssids.data.length === 2" class="warning margin-top-10">
        {{ $t("dialogs.deleteWirelessNetwork.line2") }}
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="deleteSsidDialog.visible = false"
          :disabled="deleteSsidDialog.loading"
          >{{ $t("button.cancel") }}</el-button
        >
        <el-button
          type="danger"
          @click="deleteSsidHandler"
          :loading="deleteSsidDialog.loading"
          >{{ $t("button.delete") }}</el-button
        >
      </span>
    </el-dialog>

    <!-- Edit ssid -->
    <el-dialog
      v-if="editSsidDialog.visible"
      :title="
        editSsidDialog.isCreating
          ? $t('dialogs.wirelessNetwork.create')
          : $t('dialogs.wirelessNetwork.edit')
      "
      :visible.sync="editSsidDialog.visible"
      @close="resetEditSsidDialogState"
      :width="windowWidth < 1024 ? '400px' : '420px'"
    >
      <el-form
        label-position="top"
        :model="ssidForm"
        :rules="rules"
        ref="ssidForm"
        label-width="120px"
        class="user-account__ssid-form"
        hide-required-asterisk
      >
        <div class="warning-description">
          <img class="warning-icon" src="@/assets/warning.svg" alt="warning" />
          <span>
            {{ $t("messages.gatewayConectionSupport") }}
          </span>
        </div>
        <el-form-item :label="$t('labels.ssid')" prop="ssid">
          <el-input
            type="text"
            v-model="ssidForm.ssid"
            autocomplete="new-password"
          />
        </el-form-item>

        <div
          v-if="!editPassword && !editSsidDialog.isCreating"
          class="user-ssids__edit-password"
          @click="editPassword = true"
        >
          {{ $t("actions.changeWirelessPassword") }}
        </div>

        <template v-if="!editPassword && !editSsidDialog.isCreating">
          <div class="margin-top-10">
            <el-checkbox
              size="medium"
              border
              v-model="ssidForm.hidden"
              :disabled="disableCheckbox && !ssidForm.hidden"
            >
              <b>{{ $t("labels.hidden") }}</b>
            </el-checkbox>
            <div class="hidden-description">
              {{ $t("messages.hiddenNetworkWarning") }}
            </div>
          </div>
        </template>

        <template v-if="editPassword || editSsidDialog.isCreating">
          <el-form-item :label="$t('formFields.password')" prop="password">
            <el-input
              :type="revealPassword ? 'text' : 'password'"
              v-model="ssidForm.password"
              autocomplete="new-password"
            >
              <el-button
                tabindex="-1"
                @click="revealPassword = !revealPassword"
                slot="append"
                icon="el-icon-view"
              ></el-button>
            </el-input>
          </el-form-item>
          <el-form-item
            :label="$t('formFields.confirmPassword')"
            prop="confirmPassword"
          >
            <el-input
              :type="revealConfirmPassword ? 'text' : 'password'"
              v-model="ssidForm.confirmPassword"
              autocomplete="new-password"
            >
              <el-button
                tabindex="-1"
                @click="revealConfirmPassword = !revealConfirmPassword"
                slot="append"
                icon="el-icon-view"
              ></el-button>
            </el-input>
            <div
              v-if="ssidForm.error.length"
              class="el-form-item__error el-form-item__error--server"
            >
              {{ ssidForm.error }}
            </div>
          </el-form-item>
        </template>
        <div
          class="margin-top-10"
          v-if="editPassword || editSsidDialog.isCreating"
        >
          <el-checkbox
            size="medium"
            border
            v-model="ssidForm.hidden"
            :disabled="disableCheckbox && !ssidForm.hidden"
          >
            <b>{{ $t("labels.hidden") }}</b>
          </el-checkbox>
          <div class="hidden-description">
            {{ $t("messages.hiddenNetworkWarning") }}
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="resetEditSsidDialogState"
          :disabled="editSsidDialog.loading"
          >{{ $t("button.cancel") }}</el-button
        >
        <el-button
          type="primary"
          @click="editSsidHandler"
          :loading="editSsidDialog.loading"
        >
          {{
            editSsidDialog.isCreating ? $t("button.create") : $t("button.save")
          }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as actionTypes from "@/store/action-types.ts";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapState("clients", {
      ssids: "ssids"
    }),
    ...mapState("auth", {
      user: "user"
    }),
    ...mapGetters("auth", ["isAdmin"]),

    sortedSsids() {
      if (!this.ssids || !this.ssids.data) return [];
      return [...this.ssids.data].sort((a, b) => {
        if (a.internal) return -1;
        if (b.internal) return 1;
        return 0;
      });
    }
  },

  data() {
    return {
      revealPassword: false,
      revealConfirmPassword: false,
      editPassword: false,
      ssidForm: {
        ssid: "",
        password: "",
        confirmPassword: "",
        hidden: false,
        error: ""
      },
      rules: {
        ssid: [
          {
            required: true,
            message: this.$t("messages.WirelessNetworkRequired"),
            trigger: "blur"
          },
          {
            min: 2,
            max: 32,
            message: this.$t("messages.ssidlLabelLength"),
            trigger: "blur"
          }
        ]
      },
      ssidToEdit: null,
      editSsidDialog: {
        isCreating: false,
        visible: false,
        loading: false
      },
      showPassword: false,
      ssidToDelete: null,
      deleteSsidDialog: {
        visible: false,
        loading: false
      },
      disableCheckbox: false,
      ssidPassword: process.env.VUE_APP_SSID_PASSWORD,
      windowWidth: window.innerWidth
    };
  },

  watch: {
    editPassword(newValue) {
      if (newValue) this.addValidationRules();
    },
    "ssidForm.hidden": {
      handler(newValue) {
        if (newValue) this.disableCheckbox = false;
      }
    }
  },

  methods: {
    ...mapActions("clients", {
      createSsid: actionTypes.CLIENTS_CREATE_SSID,
      updateSsid: actionTypes.CLIENTS_UPDATE_SSID,
      deleteSsid: actionTypes.CLIENTS_DELETE_SSID
    }),
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 0 && row.internal) {
        return "internal-ssid";
      }
      return "";
    },
    resetEditSsidDialogState() {
      this.ssidForm = {
        ssid: "",
        password: "",
        confirmPassword: "",
        hidden: false,
        error: ""
      };
      this.editPassword = false;
      this.ssidToEdit = null;
      this.editSsidDialog = {
        isCreating: false,
        visible: false,
        loading: false
      };
      this.ssidToDelete = null;
      this.deleteSsidDialog = {
        visible: false,
        loading: false
      };
      this.ssids.data.sort(function(a, b) {
        if (a.internal) return -1;
        else if (b.internal) return 1;
        else return 0;
      });
      this.disableCheckbox = false;
      delete this.rules.password;
      delete this.rules.confirmPassword;
    },
    createNewSsid() {
      this.ssidToEdit = null;
      this.editSsidDialog.visible = true;
      this.editSsidDialog.isCreating = true;

      this.addValidationRules();
    },

    handleEdit(index, row) {
      this.ssidForm.ssid = row.ssid;
      this.ssidToEdit = row;
      this.editSsidDialog.visible = true;
      this.ssids.data.forEach(element => {
        if (element.hidden) this.disableCheckbox = true;
      });
      this.ssidForm.hidden = row.hidden;
    },
    handleDelete(index, row) {
      this.ssidToDelete = row;
      this.deleteSsidDialog.visible = true;
    },

    async editSsidHandler() {
      this.$refs.ssidForm.validate(async valid => {
        if (valid) {
          try {
            this.editSsidDialog.loading = true;
            let payload = {
              ssid: this.ssidForm.ssid.trim()
            };

            if (this.editPassword) {
              payload = {
                ...payload,
                password: this.ssidForm.password
              };
            }

            if (this.editSsidDialog.isCreating) {
              payload = {
                ...payload,
                password: this.ssidForm.password,
                clientId: this.user.data.clientId,
                hidden: this.ssidForm.hidden
              };
              await this.createSsid(payload);
            } else {
              payload.id = this.ssidToEdit.id;
              payload.hidden = this.ssidForm.hidden;
              await this.updateSsid(payload);
            }

            this.$notify({
              title: this.$t("statusMessages.success"),
              message: this.editSsidDialog.isCreating
                ? this.$t("messages.wirelessNetworkCreated")
                : this.$t("messages.wirelessNetworkUpdated"),
              type: "success"
            });
          } catch (error) {
            this.$notify.error({
              title: this.$t("statusMessages.error"),
              message: this.$t("messages.genericError")
            });
          } finally {
            this.resetEditSsidDialogState();
          }
        }
      });
    },

    async deleteSsidHandler() {
      try {
        this.deleteSsidDialog.loading = true;
        await this.deleteSsid(this.ssidToDelete.id);
        this.$notify({
          title: this.$t("statusMessages.success"),
          message: this.$t("messages.wirelessNetworkDeleted"),
          type: "success"
        });
      } catch (error) {
        this.$notify.error({
          title: this.$t("statusMessages.error"),
          message: this.$t("messages.genericError")
        });
      } finally {
        this.deleteSsidDialog.loading = false;
        this.deleteSsidDialog.visible = false;
        this.ssidToDelete = null;
      }
    },

    addValidationRules() {
      const validateNewPassword = (rule, value, callback) => {
        if (value === "") {
          callback(new Error(this.$t("messages.provideWirelessPassword")));
        }
        callback();
      };
      const validateNewPasswordConfirmation = (rule, value, callback) => {
        if (value === "") {
          callback(new Error(this.$t("messages.confirmWirelessPassword")));
        } else if (value !== this.ssidForm.password) {
          callback(new Error(this.$t("messages.passwordsDoNotMatch")));
        } else {
          callback();
        }
      };
      const validateUniqueSSID = (rule, value, callback) => {
        if (!value) {
          callback(new Error("Please enter a Wireless Network SSID"));
        } else if (
          !this.editSsidDialog.isCreating &&
          this.ssidForm.ssid === value
        ) {
          console.log(this.ssidForm.ssid);
          callback();
        } else if (this.ssids?.data?.some(ssid => ssid.ssid === value)) {
          console.log("here 2");
          callback(
            new Error("SSID already exists, please choose a different name.")
          );
        } else {
          callback();
        }
      };
      this.rules = {
        ...this.rules,
        ssid: [
          ...(this.rules.ssid || []),
          { validator: validateUniqueSSID, trigger: "blur" }
        ],
        password: [{ validator: validateNewPassword, trigger: "blur" }],
        confirmPassword: [
          { validator: validateNewPasswordConfirmation, trigger: "blur" }
        ]
      };
    }
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  }
};
</script>

<style lang="scss">
.user-ssids {
  padding: 24px 0;

  .internal-ssid {
    background-color: #f2f6fc;
  }

  .password-view {
    display: flex;
    align-items: center;
    position: relative;

    &__eye {
      position: absolute;
      right: 0;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .warning-description {
    display: flex;
    text-align: left;
    align-content: center;
    font-size: 16px;
    margin-bottom: 8px;
  }

  .warning-icon {
    padding-right: 5px;
  }

  .hidden-description {
    margin-top: 5px;
    font-size: 13px;
  }

  .warning {
    font-size: 16px;
    font-weight: bold;
  }

  .margin-top-10 {
    margin-top: 10px;
  }

  .margin-bottom-10 {
    margin-bottom: 10px;
  }

  &__container {
    display: flex;
    justify-content: center;

    .el-table {
      flex: 0 0 420px;
    }

    &--admin .el-table {
      flex: 0 0 621px;
    }
  }

  &__new.el-button {
    margin-bottom: 24px;
  }

  .button-column {
    display: flex;
    justify-content: center;
  }

  &__edit-password {
    text-align: left;
    cursor: pointer;
    color: $--color-primary;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
