<template>
  <div class="user-tags">
    <div v-if="resources.loading" v-loading="true" class="user-tags__loading" />
    <div v-else-if="resources.error">
      {{ $t("messages.errorOccurred") }}
    </div>
    <template v-else>
      <div class="asset-area">
        <h3>{{ $tc("labels.tag", 2).toUpperCase() }}</h3>
        <span class="subtitle"
          >{{ $t("labels.quantity") }}: {{ filteredTableData?.length }}</span
        >
        <div class="table-selected-filters">
          <!-- Search Input -->
          <el-input
            slot="header"
            v-model="search"
            :placeholder="$t('placeholder.search')"
            clearable
            @input="applyFilters()"
          ></el-input>

          <!-- Popover Filter Modal -->
          <el-popover
            placement="left"
            :width="windowWidth > 500 ? 450 : 350"
            trigger="click"
            v-model="showFilterModal"
          >
            <el-form label-position="top">
              <!-- Last Seen Filter -->
              <el-form-item :label="$t('labels.lastSeen')">
                <div style="width: 100%;">
                  <div
                    :style="{
                      maxWidth: dynamicMaxWidth,
                      margin: '10px auto 15px'
                    }"
                  >
                    <el-slider
                      v-model="selectedFilters.lastSeen"
                      range
                      show-stops
                      :marks="marks"
                      :max="120"
                      :min="0"
                      :step="20"
                      :format-tooltip="customTooltip"
                      @change="clearFilters('noBatteryOrOutdated')"
                    >
                    </el-slider>
                  </div>
                </div>
              </el-form-item>

              <!-- Battery Filter -->
              <el-form-item :label="$t('labels.battery')">
                <el-checkbox-group v-model="selectedFilters.battery">
                  <el-checkbox
                    :label="0"
                    @change="clearFilters('noBatteryOrOutdated')"
                    >{{ $t("labels.noBattery") }}</el-checkbox
                  >
                  <el-checkbox
                    :label="1"
                    @change="clearFilters('noBatteryOrOutdated')"
                    >{{ $t("labels.low") }}</el-checkbox
                  >
                  <el-checkbox
                    :label="2"
                    @change="clearFilters('noBatteryOrOutdated')"
                    >{{ $t("labels.medium") }}</el-checkbox
                  >
                  <el-checkbox
                    :label="3"
                    @change="clearFilters('noBatteryOrOutdated')"
                    >{{ $t("labels.high") }}</el-checkbox
                  >
                </el-checkbox-group>
              </el-form-item>

              <!-- Active Filter -->
              <el-form-item :label="$t('labels.state')">
                <el-checkbox-group v-model="selectedFilters.active">
                  <el-checkbox
                    :label="true"
                    @change="clearFilters('noBatteryOrOutdated')"
                    >{{ $t("labels.active") }}</el-checkbox
                  >
                  <el-checkbox
                    :label="false"
                    @change="clearFilters('noBatteryOrOutdated')"
                    >{{ $t("labels.inactive") }}</el-checkbox
                  >
                </el-checkbox-group>
              </el-form-item>

              <!-- No Battery OR Outdated AND Installed Filter -->
              <el-form-item>
                <span slot="label" style="color: red;">{{
                  $t("labels.maintenanceRequired")
                }}</span>
                <el-checkbox
                  v-model="selectedFilters.noBatteryOrOutdated"
                  @change="clearFilters('other')"
                >
                  {{ $t("messages.noBatteryOrOutdated.installedDevicesWith") }}
                  <b>{{ $t("messages.noBatteryOrOutdated.noBattery") }}</b>
                  {{ $t("messages.noBatteryOrOutdated.or") }}
                  <b>{{ $t("messages.noBatteryOrOutdated.noSignal") }}</b>
                  ({{ $t("messages.noBatteryOrOutdated.moreThanSevenDays") }}).
                </el-checkbox>
              </el-form-item>

              <!-- Apply or Clear Filter Buttons -->
              <span>
                <el-button size="mini" @click="clearFilters('all')">{{
                  $t("button.clearAll")
                }}</el-button>
                <el-button size="mini" @click="applyFilters" type="primary">{{
                  $t("button.confirm")
                }}</el-button>
              </span>
            </el-form>

            <!-- Button Reference that Opens the Popover -->
            <el-button slot="reference" icon="el-icon-s-operation">
              {{ $t("labels.filter") }}
            </el-button>
          </el-popover>
        </div>
        <el-table
          @row-click="data => $emit('row-click', data)"
          :data="filteredTableData"
          class="user-tags-table"
          height="45vh"
          border
        >
          <el-table-column :label="$t('labels.tagId').toUpperCase()">
            <template slot-scope="scope">
              <TruncatedTooltip
                :content="scope.row.tagId.toString(16).toUpperCase()"
              >
                {{ scope.row.tagId.toString(16).toUpperCase() }}
              </TruncatedTooltip>
            </template>
          </el-table-column>
          <el-table-column :label="$t('labels.label').toUpperCase()">
            <template slot-scope="scope">
              <TruncatedTooltip :content="scope.row.label">
                {{ scope.row.label }}
              </TruncatedTooltip>
            </template>
          </el-table-column>
          <el-table-column
            :resizable="false"
            :label="$t('labels.status').toUpperCase()"
            width="100"
            v-if="isTracker"
          >
            <template slot-scope="scope">
              {{ scope.row.status }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('labels.range').toUpperCase()" width="78">
            <template slot-scope="scope">
              {{ -scope.row.threshold - 16 + " / 16" }}
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('labels.lastSeen').toUpperCase()"
            min-width="150"
          >
            <template slot-scope="scope">
              <span
                class="last-update"
                v-bind:style="{ color: computedColor(scope.row) }"
                style="text-align: left"
                v-if="
                  scope.row.lastUpdate &&
                    ((isClientDefault && scope.row.active) ||
                      scope.row.lastPosition != null ||
                      (scope.row.lastPosition == null && !isClientDefault))
                "
              >
                <el-tooltip
                  :disabled="!isTagOutdated(scope.row)"
                  :content="$t('tooltip.outdatedTag')"
                  placement="top"
                >
                  <span>
                    {{
                      moment
                        .tz(scope.row.lastUpdate * 1000, clientData.timezone)
                        .format("MMM DD YYYY, HH:mm")
                    }}
                  </span>
                </el-tooltip>
              </span>
              <span v-else>
                <hr />
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('labels.state').toUpperCase()"
            min-width="100"
          >
            <template slot-scope="scope">
              {{
                scope.row.active ? $t("labels.active") : $t("labels.inactive")
              }}
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('labels.battery').toUpperCase()"
            width="85"
            align="center"
          >
            <template slot-scope="scope">
              <span
                v-if="
                  scope.row.battery !== null &&
                    ((isClientDefault && scope.row.active) ||
                      scope.row.lastPosition != null ||
                      (scope.row.lastPosition == null && !isClientDefault))
                "
              >
                <template v-if="scope.row.battery === 0">
                  <img src="@/assets/battery_dead.svg" alt="no battery" />
                </template>
                <template v-else-if="scope.row.battery === 3">
                  <img src="@/assets/battery-full.svg" alt="full battery" />
                </template>
                <template v-else-if="scope.row.battery === 2">
                  <img src="@/assets/battery-high.svg" alt="high battery" />
                </template>
                <template v-else-if="scope.row.battery === 1">
                  <img src="@/assets/battery-medium.svg" alt="medium battery" />
                </template>
                <template v-else>
                  <hr />
                </template>
              </span>
              <span v-else>
                <hr />
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('labels.operations').toUpperCase()"
            width="115"
          >
            <template slot-scope="scope">
              <div class="edit-buttons">
                <el-button
                  size="mini"
                  @click="handleEdit(scope.$index, scope.row)"
                  ><i class="el-icon-edit"></i
                ></el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-table
          @row-click="data => $emit('row-click', data)"
          :data="filteredTableData"
          border
          class="expandable-table"
        >
          <el-table-column type="expand">
            <template slot-scope="scope">
              <p>
                <b>{{ $t("labels.tagId") }}:</b>
                {{ scope.row.tagId.toString(16).toUpperCase() }}
              </p>
              <p>
                <b>{{ $t("labels.label") }}:</b> {{ scope.row.label }}
              </p>
              <p>
                <b>{{ $t("labels.range") }}:</b>
                {{ -scope.row.threshold - 16 + " / 16" }}
              </p>
              <p>
                <b>{{ $t("labels.lastSeen") }}:</b>
                <span
                  v-bind:style="{ color: computedColor(scope.row) }"
                  style="text-align: left"
                  v-if="
                    scope.row.lastUpdate &&
                      ((isClientDefault && scope.row.active) ||
                        scope.row.lastPosition != null ||
                        (scope.row.lastPosition == null && !isClientDefault))
                  "
                >
                  <el-tooltip
                    :disabled="!isTagOutdated(scope.row)"
                    :content="$t('tooltip.outdatedTag')"
                    placement="top"
                  >
                    <span>
                      {{
                        moment
                          .tz(scope.row.lastUpdate * 1000, clientData.timezone)
                          .format("MMM DD YYYY, HH:mm")
                      }}
                    </span>
                  </el-tooltip>
                </span>
                <span v-else>
                  -
                </span>
              </p>
            </template>
          </el-table-column>
          <el-table-column
            :label="$tc('labels.tag', 1).toUpperCase()"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.row.label
                    ? scope.row.label
                    : scope.row.tagId.toString(16).toUpperCase()
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('labels.battery').toUpperCase()"
            min-width="85"
            align="center"
          >
            <template slot-scope="scope">
              <span
                v-if="
                  scope.row.battery !== null &&
                    ((isClientDefault && scope.row.active) ||
                      scope.row.lastPosition != null ||
                      (scope.row.lastPosition == null && !isClientDefault))
                "
              >
                <template v-if="scope.row.battery === 0">
                  <img src="@/assets/battery_dead.svg" alt="no battery" />
                </template>
                <template v-else-if="scope.row.battery === 3">
                  <img src="@/assets/battery-full.svg" alt="full battery" />
                </template>
                <template v-else-if="scope.row.battery === 2">
                  <img src="@/assets/battery-high.svg" alt="high battery" />
                </template>
                <template v-else-if="scope.row.battery === 1">
                  <img src="@/assets/battery-medium.svg" alt="medium battery" />
                </template>
                <template v-else>
                  <hr />
                </template>
              </span>
              <span v-else>
                <hr />
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('labels.operations').toUpperCase()"
            width="115"
            align="center"
          >
            <template slot-scope="scope">
              <div class="edit-buttons">
                <el-button
                  size="mini"
                  @click="handleEdit(scope.$index, scope.row)"
                  ><i class="el-icon-edit"></i
                ></el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </template>

    <div v-if="resources.data.tags.length" class="refresh">
      <el-button type="primary" @click="updateBeacons()">{{
        $t("button.refreshStatus")
      }}</el-button>
    </div>

    <EditDialog
      assetType="tag"
      :assetPrevInfo="tagToEdit"
      :isVisible="editTagDialog.visible"
      :rules="rules"
      @update-edit-dialog-visibility="value => (editTagDialog.visible = value)"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import * as actionTypes from "@/store/action-types";
import tagsApi from "@/modules/tags/api/tags.api";
import moment from "moment-timezone";
import positionLogsApi from "@/modules/position-logs/api/position-logs.api";
import EditDialog from "@/components/EditDialog.vue";
import TruncatedTooltip from "@/components/TruncatedTooltip.vue";

export default {
  components: {
    EditDialog,
    TruncatedTooltip
  },
  data() {
    return {
      certifyEditTagDialogSave: false,
      childTagToAdd: null,
      editTagDialog: {
        visible: false,
        loading: false
      },
      finalTags: [],
      inactiveTags: [],
      moment,
      orphanTags: [],
      routeList: [],
      routesAssociatedToTag: [],
      rules: {
        label: [
          {
            max: 45,
            message: this.$t("messages.labelMaxLength"),
            trigger: "blur"
          }
        ]
      },
      showAssociatedRoutes: false,
      tagForm: {
        tagId: 0,
        label: "",
        active: false,
        threshold: null,
        battery: -1,
        lastSeen: -1,
        error: "",
        beaconId: null
      },
      tagsSelected: [],
      tagsToDelete: [],
      tagsToSend: [],
      tagToEdit: {},
      tagToReplace: null,
      tempOrphanTags: [],
      search: "",
      selectedFilters: {
        lastSeen: [120, 120],
        active: [],
        battery: [],
        noBatteryOrOutdated: false
      },
      showFilterModal: false,
      filteredTableData: [],
      marks: {
        0: "<",
        20: this.$t("labels.threeMonths"),
        40: this.$t("labels.oneMonth"),
        60: this.$t("labels.twoWeeksNumber"),
        80: this.$t("labels.oneWeek"),
        100: this.$t("labels.today"),
        120: this.$t("labels.all")
      }
    };
  },
  props: {
    showOperations: {
      type: Boolean,
      default: true
    },
    showActiveRoute: {
      type: Boolean,
      default: false
    },
    batteryIcon: {
      type: Object,
      required: true
    },
    windowWidth: {
      type: Number,
      default: 769
    }
  },

  computed: {
    ...mapState("user", {
      resources: "resources"
    }),
    ...mapState("clients", {
      clientData: "data",
      clientAnchors: "anchors"
    }),
    ...mapGetters("auth", ["isAdmin", "isTracker"]),
    ...mapGetters("clients", ["isClientDefault"]),
    filteredDataBySearch() {
      const trimmedSearch = this.search?.trim();

      const matchesSearch = tag =>
        !trimmedSearch ||
        tag.tagId
          .toString(16)
          .toLowerCase()
          .trim()
          .includes(trimmedSearch.toLowerCase()) ||
        tag.label
          ?.trim()
          .toLowerCase()
          .includes(trimmedSearch.toLowerCase());

      return this.resources.data.tags.filter(
        tag => tag.type === 1 && matchesSearch(tag)
      );
    },
    dynamicMaxWidth() {
      return this.windowWidth > 500 ? "420px" : "320px";
    }
  },

  watch: {
    "$i18n.locale": {
      handler() {
        this.marks = {
          0: "<",
          20: this.$t("labels.threeMonths"),
          40: this.$t("labels.oneMonth"),
          60: this.$t("labels.twoWeeksNumber"),
          80: this.$t("labels.oneWeek"),
          100: this.$t("labels.today"),
          120: this.$t("labels.all")
        };
      }
    }
  },

  methods: {
    ...mapActions("user", {
      userSetResourcesTags: actionTypes.USER_SET_RESOURCES_TAGS
    }),
    computedColor(tag) {
      if (this.isTagOutdated(tag)) return "red";
      else return "";
    },
    isTagOutdated(tag) {
      const oneWeekAgo = Math.ceil(
        moment()
          .tz(this.clientData.timezone)
          .subtract(7, "days")
          .valueOf() / 1000
      );
      if (tag.lastUpdate < oneWeekAgo) return true;
      return false;
    },
    updateChildTags(tagList) {
      const updatedTagList = tagList;
      updatedTagList.forEach(elem => {
        elem.childTags = [];
      });
      updatedTagList.forEach(element => {
        const index = updatedTagList.findIndex(
          elem => elem.id == element.parentId
        );
        if (index !== -1) {
          updatedTagList[index].childTags.push({
            id: element.tagId,
            label: element.label,
            parentId: element.parentId
          });
        }
      });

      return updatedTagList;
    },
    async updateBeacons() {
      try {
        const { data } = await tagsApi.getRefreshStatus();
        const updatedTagList = this.updateChildTags(data);
        this.orderTags(updatedTagList);
        this.applyFilters();
      } catch (_) {
        // DO nothing
      }
    },

    thresholdToRange(threshold) {
      return threshold >= -16 ? 0 : -threshold - 16;
    },

    handleEdit(index, row) {
      this.tagToEdit = row;
      this.editTagDialog.visible = true;
    },

    sortByLastUpdate(arr) {
      arr.sort((a, b) => {
        if (a.lastUpdate < b.lastUpdate) return 1;
        else return -1;
      });
    },

    orderTags(updatedTagList) {
      const noBatteryTags = updatedTagList.filter(
        tag => tag.battery === 0 && tag.active
      );
      this.sortByLastUpdate(noBatteryTags);

      const tagsNotSeen = updatedTagList.filter(
        tag => this.isTagOutdated(tag) && tag.battery != 0 && tag.active
      );
      this.sortByLastUpdate(tagsNotSeen);

      const badTags = [].concat(noBatteryTags).concat(tagsNotSeen);

      const restOfTags = updatedTagList.filter(
        tag => tag.battery != 0 && badTags.indexOf(tag) === -1 && tag.active
      );
      this.sortByLastUpdate(restOfTags);

      const notActiveTags = updatedTagList.filter(tag => !tag.active);
      this.sortByLastUpdate(notActiveTags);

      const sortedTagList = badTags.concat(restOfTags).concat(notActiveTags);

      this.userSetResourcesTags(sortedTagList);
    },

    confirmSave() {
      if (this.tagsSelected.length != 0 || this.tagToReplace != null)
        this.certifyEditTagDialogSave = true;
      else this.saveTag(false);
    },

    discardChanges() {
      this.tagsSelected = [];
      this.tagToReplace = null;
      this.certifyEditTagDialogDiscard = false;
      this.editTagDialog.visible = false;
    },

    async replaceTag() {
      this.replaceTagDialog.loading = true;
      try {
        const tag = this.resources.data.tags.find(
          tag => tag.id === this.tagToReplace
        );
        const oldTag = this.resources.data.tags.find(
          tag => tag.id === this.tagToEdit.tagId
        );

        const lastPosition = oldTag.lastPosition;
        await positionLogsApi.updateTagPosition(this.tagToEdit.id, {
          tagId: tag.id,
          x: lastPosition.x,
          y: lastPosition.y
        });

        this.$notify({
          title: this.$t("statusMessages.success"),
          message: this.$t("messages.beaconReplaced"),
          type: "success"
        });
      } catch (err) {
        this.$notify.error({
          title: this.$t("statusMessages.error"),
          message: this.$t("messages.genericError")
        });
      } finally {
        this.replaceTagDialog.loading = false;
        this.replaceTagDialog.visible = false;
      }
    },

    toHex(str) {
      return str
        .toString(16)
        .toUpperCase()
        .match(/.{1,2}/g)
        .join("");
    },

    filterLastSeen(range, row) {
      if (!row.lastUpdate) return false; // Return false if there's no last update
      if (range[0] === 120 || range[1] === 120) return true; // Handle no filters applied

      const lastUpdateDate = moment.tz(
        row.lastUpdate * 1000,
        this.clientData.timezone
      );
      const isAllDataVisible =
        (this.isClientDefault && row.active) ||
        row.lastPosition !== null ||
        (!this.isClientDefault && row.lastPosition === null);

      // Date range mapping
      const dateRanges = {
        0: moment()
          .startOf("day")
          .subtract(91, "days"), // 3 months and one day
        20: moment()
          .startOf("day")
          .subtract(90, "days"), // 3 months ago
        40: moment()
          .startOf("day")
          .subtract(30, "days"), // 1 month ago
        60: moment()
          .startOf("day")
          .subtract(14, "days"), // 14 days ago
        80: moment()
          .startOf("day")
          .subtract(7, "days"), // 7 days ago
        100: moment().startOf("day") // Today
      };

      const minKey = Math.min(...range);
      const maxKey = Math.max(...range);
      const startDate = dateRanges[minKey]; // Older date
      const endDate = dateRanges[maxKey]; // More recent date

      if (!startDate || !endDate) return false; // Handle undefined values

      // If minKey is 0, include everything older than maxKey
      if (minKey === 0) {
        return (
          lastUpdateDate.isSameOrBefore(dateRanges[maxKey], "day") &&
          isAllDataVisible
        );
      }

      // Standard filtering logic: Show only records within the range
      return (
        lastUpdateDate.isSameOrAfter(startDate, "day") &&
        lastUpdateDate.isSameOrBefore(endDate, "day") &&
        isAllDataVisible
      );
    },

    showNoBatteryOrOutated() {
      this.filteredTableData = this.filteredDataBySearch.filter(tag => {
        const batteryMatch = tag.battery === 0;
        const activeMatch = tag.active === true;
        const lastSeenMatch = this.isTagOutdated(tag);
        const isAllDataVisible =
          (this.isClientDefault && tag.active) ||
          tag.lastPosition != null ||
          (!this.isClientDefault && tag.lastPosition == null);

        return (
          (batteryMatch || lastSeenMatch) && activeMatch && isAllDataVisible
        );
      });
    },

    applyFilters() {
      const {
        battery,
        active,
        lastSeen,
        noBatteryOrOutdated
      } = this.selectedFilters;

      if (noBatteryOrOutdated) {
        this.showNoBatteryOrOutated();
      } else {
        const isBatteryFiltered = battery?.length > 0;
        const isLastSeenFiltered = lastSeen?.length > 0;
        const isActiveFiltered = active?.length > 0;

        this.filteredTableData = this.filteredDataBySearch.filter(tag => {
          const batteryMatch = isBatteryFiltered
            ? battery.includes(tag.battery)
            : true;
          const activeMatch = isActiveFiltered
            ? active.includes(tag.active)
            : true;
          const lastSeenMatch = isLastSeenFiltered
            ? this.filterLastSeen(lastSeen, tag)
            : true;
          const isAllDataVisible =
            (this.isClientDefault && tag.active) ||
            tag.lastPosition != null ||
            (!this.isClientDefault && tag.lastPosition == null);

          if (isBatteryFiltered) {
            return isAllDataVisible
              ? batteryMatch && activeMatch && lastSeenMatch
              : false;
          } else if (!isBatteryFiltered) {
            return activeMatch && lastSeenMatch;
          } else {
            return true;
          }
        });
      }

      this.showFilterModal = false;
    },

    clearFilters(filterType = "all") {
      // Handle clearing based on the filterType argument
      switch (filterType) {
        case "all":
          // Reset all filters to default values
          this.selectedFilters = {
            lastSeen: [120, 120],
            active: [],
            battery: [],
            noBatteryOrOutdated: false
          };
          this.filteredTableData = [...this.filteredDataBySearch];
          this.showFilterModal = false;
          break;

        case "noBatteryOrOutdated":
          // Specifically clear the 'noBatteryOrOutdated' filter
          this.selectedFilters.noBatteryOrOutdated = false;
          break;

        case "other":
          // Clear only the 'other' related filters when 'noBatteryOrOutdated' is true
          if (this.selectedFilters.noBatteryOrOutdated) {
            this.selectedFilters.lastSeen = [120, 120];
            this.selectedFilters.battery = [];
            this.selectedFilters.active = [];
          }
          break;

        default:
          console.warn("Unknown filter type:", filterType);
          break;
      }
    },
    customTooltip(value) {
      let dateText = "";

      if (value === 120) {
        return this.marks[120];
      } else if (value === 100) {
        dateText = moment()
          .startOf("day")
          .format("LL"); // Show today's date
      } else if (value === 80) {
        dateText = moment()
          .startOf("day")
          .subtract(7, "days")
          .format("LL"); // 1 week ago
      } else if (value === 60) {
        dateText = moment()
          .startOf("day")
          .subtract(14, "days")
          .format("LL"); // 2 weeks ago
      } else if (value === 40) {
        dateText = moment()
          .startOf("day")
          .subtract(1, "months")
          .format("LL"); // 1 month ago
      } else if (value === 20) {
        dateText = moment()
          .startOf("day")
          .subtract(3, "months")
          .format("LL"); // 3 months ago
      } else if (value === 0) {
        dateText = `< ${moment()
          .startOf("day")
          .subtract(91, "days")
          .format("LL")}`; // More than 3 months ago
      }

      return dateText;
    }
  },

  async created() {
    this.isLoading = true;
    this.orderTags(this.resources.data.tags);
    this.filteredTableData = [...this.filteredDataBySearch];
    this.clearFilters();
    this.isLoading = false;
  }
};
</script>

<style lang="scss">
.user-tags {
  padding: 24px 0;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .el-dialog {
    width: 920px !important;

    @media (max-width: $md) {
      width: 95% !important;
      display: flex;
      flex-direction: column;
    }
  }

  .dialog-info {
    margin-bottom: 10px;
  }

  .location-area {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 1024px;

    @media (max-width: 1400px) {
      width: 100%;
    }

    // h3 {
    //   margin-bottom: 1.5rem;
    // }
  }

  .asset-area {
    width: 1024px;

    @media (max-width: 1400px) {
      width: 100%;
    }

    // h3 {
    //   margin-bottom: 1.5rem;
    // }
  }

  .user-tags-table {
    @media (max-width: $sm) {
      display: none;
    }
  }

  .expandable-table {
    display: none;

    @media (max-width: $sm) {
      display: block;
    }
  }
  .subtitle {
    padding-top: 10px;
    padding-bottom: 1.5rem;
    display: block;
  }

  .range-level {
    margin-top: 8%;
  }

  .checkbox-active {
    margin-top: 6%;
  }

  .threshold-slider {
    margin-top: 8%;
  }

  .min-max {
    display: block;

    .min {
      float: left;
    }

    .max {
      float: right;
    }
  }

  #hidden-battery {
    position: absolute;
    top: -500px;
    z-index: -1;
  }

  .background-animation {
    -webkit-animation-name: animation;
    -webkit-animation-duration: 4s;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-play-state: running;

    animation-name: animation;
    animation-duration: 4s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-play-state: running;
    background-color: white;
    width: 83%;
  }

  .anchor-list {
    display: flex;
    position: relative;

    .label-div {
      width: 83%;
    }

    &__id {
      float: left;
      margin-bottom: 3%;
      padding-top: 6px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      display: flex;
      justify-content: left;
    }

    &__delete {
      float: right;
      position: absolute;
      right: 10px;
    }
  }

  .edit-beacon-values {
    display: grid;
    grid-template-columns: 33% 34% 33%;
    margin-bottom: 2%;
  }

  .edit-beacon-form {
    display: grid;
    grid-template-columns: 50% 50%;
    border-top: 1px solid black;
    padding-top: 3%;
    margin-top: 20px;

    @media (max-width: 860px) {
      display: flex;
      flex-direction: column;
    }

    .el-form-item {
      margin-bottom: 15px;
      display: grid;
      align-items: center;
      grid-template-columns: 1% 35% 45%;
      justify-items: flex-start;
      height: min-content;

      &__content {
        width: 100%;
        text-align: left;
      }
    }

    .edit-beacon-range-level {
      .el-form-item__label {
        padding-top: 10px;
      }
      .el-form-item__content {
        margin-left: 0px;
        width: 95%;
      }
    }

    .edit-beacon-replace {
      margin-top: 13%;
      display: flex;
      align-items: center;

      .select-tags {
        width: 95%;
        margin-left: 32px;
      }
    }
    .edit-associated-beacon {
      display: flex;
      align-items: center;
      height: min-content;

      .select-beacon {
        width: 95%;
        margin-left: 32px;
      }
    }

    .edit-beacon-active {
      margin-top: 13%;
      display: flex;
      align-items: center;

      .el-checkbox {
        width: 95%;
        margin-left: 32px;
      }
    }

    .edit-beacon-select-tags {
      padding-left: 14px;
      width: 115%;

      @media (max-width: 860px) {
        width: 100%;
      }
    }

    .add-tag-btn {
      padding-left: 67px;

      @media (max-width: 860px) {
        padding-left: 22px;
      }
    }

    .edit-beacon-child-tags {
      display: grid;
      grid-template-columns: 32% 68%;
      margin-bottom: 3%;
    }

    .edit-beacon-children {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .edit-beacon-label {
      display: flex;
      justify-content: left;
      align-items: center;

      .el-input {
        margin-left: 12%;
      }
    }

    .edit-beacon-status {
      display: flex;
      justify-content: left;
      align-items: center;

      .el-checkbox {
        margin-left: 45%;
      }
    }

    .form-item-slider {
      padding-bottom: 20px;
    }

    .el-slider {
      width: 100%;

      &__marks-text {
        width: 100px;
        color: black;
        text-align: center;
      }
    }
  }

  .background-red {
    background-color: "red";
    height: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
  }

  .el-table {
    overflow-x: auto;
  }

  .low-battery {
    margin-bottom: 1%;
  }

  .tags-not-seen {
    margin-top: 3%;
    margin-bottom: 1%;
  }

  .refresh {
    margin-top: 2%;
  }

  .table-selected-filters {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-bottom: 10px;
  }

  .cell span {
    display: block;
    text-align: center;

    i {
      font-size: 24px;
    }
  }

  .cell .edit-buttons {
    display: flex;
    justify-content: center;
    i {
      font-size: 15px;
    }
  }

  &__power-source--on {
    position: relative;
    text-align: center;

    &::before {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      width: 24px;
      height: 24px;
      background-color: white;
      border-radius: 50%;
      background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAACy0lEQVRIS62VT0hUURTGv3NfERqtJPpDkKkE6ZgzU9Amg2rTSpzpD6YWtIiiTMfEsNoWkYTOTC1q0SIq1LSZqaBFhQsXUkSNWUZQYSU1gumi1Epn7on3hhnfPN/om+zu3jvnfL/vnXvufYQ51qagP5+ZK2LAdgguAnNOPF18B+MtCe6WQmnrLzsxmE6GzAKbu1rypklpFpBuCGGak6yTkklRuiTHTvW56j8Z9WYVO0Leg5LpqgCy5vo6Y0xKOakIOvLS5bmlj6UAHAFvE4guZCJszGVCY1953aXE+yRAdQ6mGwsRT9QyU3Wfu/a2+qwBtJ4ryptM25LWjMQEoBSFd9d81gCOgK8ThD3/w/2MBnWEXbUVpI6ilLH3805LGrrq0JVrw8Ohd/gdi85kSckskEfOgO8sE879i3tVvKF4Gyrz7Xg2MoT6pw9SIcynqSTgeyIIOzMF6MXV2onoFA71dOLDj1G91GMqCXkjgmmlEZCzJBujfyZNuWbix3pDeD02nJrP+EolwdYpAbFYH6nKd+Dohi2o6b2HV2ORlCLL4vGqqVkAR85qXC+ND9RkdBrHe0NJiCp+srgUqoFEW0ydz1hSAb5vAlilP3ke21YcKHCmQPrHIqi3laK6wLI4oLXIZJNVp0ZIz/Agdq1Zb9W5lkeQj8ge8p4hpvPG3TRCEnF1WuZpi+6sURNtvH95nTId/Wh20IyQjMQBCankaVeFM+S/w8x7zWYyAXHn2qw714S4LezyVGoAe7A1lyUGhBDZ6SArspZh+NdPi+dRji8iWfi8vGEoeV07g95qBt20qDB3GmN/2F3XHt9o3bIH/I1E3LwgCKMh7K5r0Y99ip494K8i5msQWJoZSI6DxeGE87QANeC4e2UthLwIGdtn4RqXAHcIBU0vyjxfzMY9rVF184mpAkQ7wCgEYXl8QDBCJAeYlG7ERLv650on8hf6Czzo4QTILgAAAABJRU5ErkJggg==");
    }
  }

  &__power-source--off {
    position: relative;
    text-align: center;

    &::before {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      width: 24px;
      height: 24px;
      background-color: white;
      border-radius: 50%;
      background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAC9UlEQVRIS62Wy08TURTGv3unmKjLtgkaE23ZwRJ6qbhSN7SUR4waVOICIv4BBlNeLRSN0RjXsoDEqBHUNU/DTmCmshM3hvpAYSF1hcagc6+5E2YyHWcGEWfXOWe+37nndUvg87yqrq7QFaWVAycBVAEISncBbBDgDQXmADyp1bR3XjLEzZCvqYkKQu7ohJyhgKuP+R0HBAWec0qvH19cfO/U++NjlbHL4Pw+KN3vdzqnjQPfFSGusnz+kd1WAtBisbQg5NZuhJ2+gpCuuKreNd9bACNy4MFexC1RIdpYPv9Y/jYAMucceG1Py+GODmytr2NjYsKXGW5pQVkwiLWREcuPA994IFB1Yn7+gwFQGXsG4KzpIcWPdHYCnKMwNOQJCTc3I9LdDRCCT8PDWBsdtSCCkPG4qrYS2Yo/FeWtvVtCySSi/f0ApZ6QcGMjIr29hrjgHCuZDL7OztpPIUBplKiM9QK44cxDqKEB0b4+V0g4lUJE2jzEbbXoJguMvaDAabdEh1IpRGWUtpNIURMsIy9kMijaIi9pUSFmJWCdAuVelTSitUEMP0qNtBSyWRRnZrybQNc/S8AWBcr8WsWeb2NVSPGBARSnp307jANb/w4YHERxampnwDxjawpwyDNFTU2I9PRYBTWGx0zRTpDtFHkW2d7nZlpkkSuyWavwK7kcipOT7vFxPiPbtAfATaeHnNBIOm1Fbi9osL6+BFLI5bDhDklLQIQDK/ZB8xM3AwkmEqjIZDyHkcsRDQSi5qp4CuCcc1Xs1OehRALRbYhzVWxfRBcNwEI8fgycL1PggAVpb8eP1dWS8XcdxmQS+8rLS/YQgE0iRCXL51etda3FYm2CkIf/Y10LQi7EVXXMWtemqMpYF4A7e4Rcq9W0e9Y+coppsdglnZBhChzcJWhTEHLFjNwTIA0v6+qOKrp+Wwhx/i8ufU6BcUXX0zVLSx+dQfn+Y5DFV3S9FcApwXklV5Swset0/QsIWQalc78CgTF5c3md9jeDjGiF9sPCPQAAAABJRU5ErkJggg==");
    }
  }
  .el-popover__reference-wrapper {
    .el-button {
      display: flex;
      align-items: center;
    }
  }
}
.el-slider__marks-text {
  color: #606266 !important;
  word-break: break-word !important;
  text-align: center;
  width: max-content;

  @media (max-width: 500px) {
    line-height: 1.5;
    width: 60px;
  }
}
</style>
