<template>
  <div class="user">
    <div class="container-settings">
      <el-tabs type="border-card" v-model="activeTab" class="scrollable-tab">
        <el-tab-pane
          v-if="!isChangePasswordUser && isClientDefault && !isTracker"
          :label="$t('tabs.manageRoutes')"
          name="routes"
        >
          <UserRoutes
            v-if="activeTab === 'routes'"
            :discardRoutesChanges="discardRoutesChanges"
            @update-discard-changes="value => (discardRoutesChanges = value)"
            @update-unsaved-changes-dialog="
              value => (unsavedChangesDialog = value)
            "
          />
        </el-tab-pane>
        <el-tab-pane
          v-if="!isChangePasswordUser && isClientDefault"
          :label="$t('tabs.placeBeacons')"
          name="beacons-location"
          ><UserBeaconsLocation v-if="activeTab === 'beacons-location'"
        /></el-tab-pane>
        <el-tab-pane
          v-if="!isChangePasswordUser"
          :label="$tc('labels.gateway', 2)"
          name="anchors"
          ><UserAnchors
        /></el-tab-pane>
        <el-tab-pane
          v-if="!isChangePasswordUser"
          :label="
            isClientDefault ? $tc('labels.beacon', 2) : $tc('labels.tag', 2)
          "
          name="beacons"
          ><UserBeacons :batteryIcon="batteryIcon" :windowWidth="windowWidth"
        /></el-tab-pane>
        <el-tab-pane
          v-if="!isChangePasswordUser && isTracker"
          :label="$tc('labels.tag', 2)"
          name="tags"
          ><UserTags :batteryIcon="batteryIcon" :windowWidth="windowWidth"
        /></el-tab-pane>
        <el-tab-pane
          v-if="isTracker"
          :label="$t('labels.scanner')"
          name="scanner"
          ><UserTagScanner :batteryIcon="batteryIcon"
        /></el-tab-pane>
        <el-tab-pane :label="$t('tabs.accountUsers')" name="account"
          ><UserAccount
        /></el-tab-pane>
        <el-tab-pane
          v-if="
            (local === 'local' && !isChangePasswordUser) || !isClientDefault
          "
          :label="
            isClientDefault
              ? $t('tabs.beaconHistory.default')
              : $t('tabs.beaconHistory.tagHistory')
          "
          name="beacon-history"
          ><UserBeaconHistory
        /></el-tab-pane>
        <el-tab-pane
          v-if="!isChangePasswordUser"
          :label="$t('tabs.wirelessNetworks')"
          name="ssids"
          ><UserSsids
        /></el-tab-pane>
        <el-tab-pane
          v-if="isAdmin && !isChangePasswordUser && isClientDefault"
          :label="$t('tabs.layout')"
          name="layout"
          ><UserLayout
        /></el-tab-pane>
        <el-tab-pane
          v-if="isAdmin && !isChangePasswordUser"
          :label="$t('tabs.apiKey')"
          name="api-key"
          ><UserApiKey
        /></el-tab-pane>
        <el-tab-pane
          v-if="!isChangePasswordUser && isClientDefault && !isTracker"
          :label="$t('tabs.documentation')"
          name="faq"
          ><UserFaq
        /></el-tab-pane>
        <!--<el-tab-pane v-if="isAdmin" label="Webhooks" name="webhook"
          ><UserWebhook
        /></el-tab-pane>-->
      </el-tabs>
    </div>
    <el-dialog
      :title="$t('dialogs.discardChanges.title')"
      :visible.sync="unsavedChangesDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="500px"
    >
      <span class="warning"
        >{{ $t("dialogs.discardChanges.line1") }} <br />
        {{ $t("dialogs.discardChanges.line2") }} <br />
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="continueEditing()" size="small">{{
          $t("button.continueEditing")
        }}</el-button>
        <el-button type="danger" @click="discardChangesFunction" size="small">{{
          $t("button.discardChanges")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script lang="js">
import { mapGetters, mapActions, mapState } from 'vuex'
import * as actionTypes from '@/store/action-types.ts'
import * as routerTypes from "@/router/router.types.ts";
import UserAccount from "@/components/user-settings/UserAccount.vue";
import UserAnchors from "@/components/user-settings/UserAnchors.vue";
import UserBeacons from "@/components/user-settings/UserBeacons.vue";
import UserTags from "@/components/user-settings/UserTags.vue";
import UserBeaconsLocation from "@/components/user-settings/UserBeaconsLocation.vue";
import UserApiKey from "@/components/user-settings/UserApiKey.vue";
import UserBeaconHistory from "@/components/user-settings/UserBeaconHistory.vue";
import UserSsids from "@/components/user-settings/UserSsids.vue";
import UserLayout from "@/components/user-settings/UserLayout.vue";
import UserFaq from "@/components/user-settings/UserFaq.vue";
import UserRoutes from "@/components/user-settings/UserRoutes.vue";
import BatteryIcon from "@/components/icons/BatteryIcon.vue";
import UserTagScanner from '@/components/user-settings/UserTagScanner.vue';
//import UserWebhook from "@/components/user-settings/components/UserWebhook.vue";

export default {
  name: "UserSettings",

  components: {
    UserAccount,
    UserAnchors,
    UserBeacons,
    UserTags,
    UserBeaconsLocation,
    UserSsids,
    UserApiKey,
    UserBeaconHistory,
    UserLayout,
    UserFaq,
    UserRoutes,
    UserTagScanner
    //UserWebhook
  },

  computed: {
    ...mapGetters("auth", ["isAdmin", "isChangePasswordUser", "isTracker"]),
    ...mapState("auth", {
      user: "user"
    }),
    ...mapState("user", {
      discardChanges: "discardChanges"
    }),
    ...mapGetters("clients", ["isClientDefault"])
  },

  data() {
    return {
      activeTab: routerTypes.ROUTE_USER_MAIN_TAB,
      local: process.env.NODE_ENV,
      unsavedChangesDialog: false,
      batteryIcon: BatteryIcon,
      discardRoutesChanges: false,
      windowWidth: window.innerWidth
    };
  },

  watch: {
    '$route': {
      deep: true,
      handler () {
        if (!this.$route.params.tab) {
          this.activeTab = routerTypes.ROUTE_USER_MAIN_TAB;
        }
      },
    },
    '$route.params.tab': {
      deep: true,
      handler (newValue) {
        this.activeTab = newValue || routerTypes.ROUTE_USER_MAIN_TAB;
      }
    },
    isClientDefault: {
      immediate: true,
      async handler(newValue) {
        if (!newValue || this.isTracker)
          this.activeTab = routerTypes.ROUTE_USER_MAIN_TAB_STATIONARY
        else
          this.activeTab = routerTypes.ROUTE_USER_MAIN_TAB;
      }
    },
    activeTab: {
      handler(newTab) {
        this.$router.push({
          name: "user-settings",
          params: { tab: newTab }
          // eslint-disable-next-line
        }).catch(_ => {
          // Do nothing
        })
        this.scrollToActiveTab();
      }
    },
    discardChanges(newValue) {
      if (newValue === true) {
        this.unsavedChangesDialog = true;
      }
    }
  },

  methods: {
    ...mapActions("clients", {
      getAnchors: actionTypes.CLIENTS_GET_ANCHORS,
      getUsers: actionTypes.CLIENTS_GET_USERS,
      getSsids: actionTypes.CLIENTS_GET_SSIDS
    }),
    ...mapActions("user", {
      setIsChanging: actionTypes.USER_SET_IS_CHANGING,
      setDiscard: actionTypes.USER_SET_DISCARD
    }),
    continueEditing() {
      this.unsavedChangesDialog = false;
      this.setDiscard(false);
    },
    discardChangesFunction() {
      if (this.activeTab === "routes") {
        this.discardRoutesChanges = true;
      }
      this.unsavedChangesDialog = false;
      this.setDiscard(false);
      this.setIsChanging(false);
    },
    scrollToActiveTab() {
      if (this.windowWidth <= 768) {
        this.$nextTick(() => {
          const activeTabEl = document.querySelector(".el-tabs__item.is-active");
          if (activeTabEl) {
            activeTabEl.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
          }
        });
      }
    },
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    }
  },

  mounted() {
    window.addEventListener("resize", this.updateWindowWidth);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.updateWindowWidth);
  },

  beforeMount() {
    if (this.isClientDefault)
      this.activeTab = routerTypes.ROUTE_USER_MAIN_TAB_STATIONARY
    if (
      this.$route.name === routerTypes.ROUTE_USER_CHANGE_PASSWORD &&
      this.$route.params.tab !== routerTypes.ROUTE_USER_MAIN_TAB
    ) {
      return this.$router.push({
        name: "user",
        params: { tab: routerTypes.ROUTE_USER_MAIN_TAB }
      });
    }

    if (this.$route.params.tab) {
      this.activeTab = this.$route.params.tab;
    } else {
      if (!this.isChangePasswordUser)
        this.$router.push({
          name: "user",
          params: { tab: this.activeTab }
        });
      else
        this.$router.push({
          name: "user",
          params: { tab: routerTypes.ROUTE_USER_ACCOUNT }
        });
    }
    this.scrollToActiveTab();
  },

  created () {
    const userClientId = this.user.data.clientId
   // this.getAnchors(userClientId)
    this.getUsers(userClientId)
    this.getSsids(userClientId)
  }
};
</script>

<style lang="scss">
.user {
  padding: 24px;

  @media (max-width: 860px) {
    padding: 48px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: $xs) {
    padding: 10px;
  }

  .container-settings {
    max-width: 80vw;
    margin: 0 10vw 0 10vw;

    @media (max-width: 1300px) {
      max-width: 100%;
      margin: 0 5px 0 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    @media (max-width: $xs) {
      margin: 0;
    }
  }
  .el-tabs__nav-wrap {
    @media (max-width: $xs) {
      overflow-x: scroll;
      scrollbar-width: none;
      white-space: nowrap;
    }
  }

  .el-tabs__nav-scroll {
    @media (max-width: $xs) {
      display: inline-flex;
    }
  }
}
</style>
