<template>
  <div class="user-beacons-location">
    <div
      v-if="resources.loading"
      v-loading="true"
      class="user-beacons__loading"
    />
    <div v-else-if="resources.error">
      {{ $t("messages.errorOccurred") }}
    </div>
    <template v-else>
      <div v-if="!clientData.mapImages.length">
        <h4>
          {{ $t("messages.noLayoutAssociated") }}
        </h4>
      </div>
      <div v-else class="container-location">
        <div>
          <LiveMap
            :show-anchors="false"
            :interval-update="false"
            :tags-removed="tagsRemoved"
            :save-changes="saveChanges"
            :cancel-changes="cancelChanges"
            :edit-tag-locations="editTagLocations"
            :tag-to-move-id="tagToMoveId"
            @tag-dragged="onTagDragged"
            @tag-clicked="onTagClicked"
            @map-clicked="onMapClicked"
            @reset-tags="resetTags"
            @set-tag-to-move-to-null="tagToMoveId = null"
          />
          <div class="small-screen-buttons">
            <div v-if="editTagLocations" class="buttons">
              <el-button size="mini" @click="cancelChangesFunction()">
                {{ $t("button.cancel") }}
              </el-button>
              <el-button type="primary" size="mini" @click="saveChanges = true">
                {{ $t("button.save") }}
              </el-button>
            </div>
            <div v-else class="buttons">
              <el-button
                type="primary"
                size="mini"
                @click="editTagLocations = true"
                ref="editButton"
              >
                {{ $t("button.editBeaconLocation") }}
              </el-button>
            </div>
          </div>
        </div>
        <div class="changes-made">
          <h3>
            {{ $t("tabs.placeBeacons") }}
          </h3>
          <p class="place-beacon-description">
            {{ $t("layout.editBeaconLocation") }}
          </p>
          <div class="highlighted">
            <h3>
              {{ $t("labels.selectedBeacon") }}
            </h3>
            <span v-if="highlightedBeacon">
              <span>
                {{
                  highlightedBeacon.tagId
                    .toString(16)
                    .toUpperCase()
                    .match(/.{1,2}/g)
                    .join("")
                }}
              </span>
              <span v-if="highlightedBeacon.label">
                ({{ highlightedBeacon.label }})
              </span>
            </span>
            <span v-else>
              {{ $t("labels.na") }}
            </span>
          </div>
          <h3>
            {{ $t("title.listOfChanges") }}
          </h3>
          <div class="full-wrapper">
            <div class="table-wrapper">
              <el-table class="full-table" :data="tags">
                <template slot="empty">
                  {{ $t("labels.noData") }}
                </template>
                <el-table-column
                  :resizable="false"
                  :label="$tc('labels.beacon', 1).toUpperCase()"
                >
                  <template slot-scope="scope">
                    {{
                      scope.row[0].tagId
                        .toString(16)
                        .toUpperCase()
                        .match(/.{1,2}/g)
                        .join("")
                    }}
                    <template v-if="scope.row[0].label">
                      {{ "(" }}
                      {{ scope.row[0].label }}
                      {{ ")" }}
                    </template>
                  </template>
                </el-table-column>
                <el-table-column
                  :resizable="false"
                  :label="$t('actions.action').toUpperCase()"
                >
                  <template slot-scope="scope">
                    {{ scope.row[1] }}
                  </template>
                </el-table-column>
                <el-table-column :resizable="false" width="25">
                  <template slot-scope="scope">
                    <div
                      @click="removeTagFromSelected(scope.$index, scope.row)"
                    >
                      <i class="el-icon-delete" />
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="large-screen-buttons">
            <div v-if="editTagLocations" class="buttons">
              <el-button size="mini" @click="cancelChangesFunction()">
                {{ $t("button.cancel") }}
              </el-button>
              <el-button type="primary" size="mini" @click="saveChanges = true">
                {{ $t("button.save") }}
              </el-button>
            </div>
            <div v-else class="buttons">
              <el-button
                type="primary"
                size="mini"
                @click="editTagLocations = true"
                ref="editButton"
              >
                {{ $t("button.editBeaconLocation") }}
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import moment from "moment-timezone";
import * as actionTypes from "@/store/action-types";
import LiveMap from "@/views/LiveMap.vue";

export default {
  components: { LiveMap },
  data() {
    return {
      moment,
      tags: [],
      tagsRemoved: null,
      saveChanges: false,
      cancelChanges: false,
      editTagLocations: false,
      highlightedBeacon: null,
      unsavedChangesDialog: false,
      tagToMoveId: null
    };
  },

  computed: {
    ...mapState("user", {
      resources: "resources",
      discardChanges: "discardChanges"
    }),
    ...mapState("clients", {
      clientData: "data"
    }),
    ...mapGetters("auth", ["isAdmin"])
  },

  watch: {
    discardChanges(newValue) {
      if (newValue === true) {
        this.unsavedChangesDialog = true;
      }
    },
    "$route.params.edit": {
      deep: true,
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.$nextTick(() => {
            if (this.$refs?.editButton?.$el) {
              this.$refs.editButton.$el.click();

              if (this.$route.params.beaconId) {
                this.tagToMoveId = this.$route.params.beaconId;
              }
            }
          });
        }
      }
    }
  },
  methods: {
    ...mapActions("user", {
      setIsChanging: actionTypes.USER_SET_IS_CHANGING,
      setDiscard: actionTypes.USER_SET_DISCARD
    }),
    onTagDragged(tag, action) {
      this.saveChanges = false;
      this.cancelChanges = false;
      this.tags.push([tag, action]);
      this.highlightedBeacon = tag;
      this.setIsChanging(true);
    },
    onMapClicked() {
      this.highlightedBeacon = null;
    },
    onTagClicked(tag) {
      this.highlightedBeacon = tag;
      if (this.editTagLocations) this.setIsChanging(true);
    },
    removeTagFromSelected(index, tag) {
      //this.tableData = this.tableData.filter(element => element.ord !== index + 1)
      this.tagsRemoved = tag;
      this.tags.splice(index, 1);
      this.tags
        .slice(index)
        .forEach(element => (element.ord = element.ord - 1));
      this.$forceUpdate();
    },
    continueEditing() {
      this.unsavedChangesDialog = false;
      this.setDiscard(false);
    },
    discardChangesFunction() {
      this.cancelChangesFunction();
      this.setDiscard(false);
      this.setIsChanging(false);
    },
    cancelChangesFunction() {
      this.cancelChanges = true;
    },
    resetTags() {
      this.tags = [];
      this.editTagLocations = false;
      this.setIsChanging(false);
      this.cancelChanges = false;
    }
  }
};
</script>

<style lang="scss">
.user-beacons-location {
  .container-location {
    display: grid;
    grid-template-columns: 60% 40%;

    @media (max-width: 1080px) {
      display: flex;
      flex-direction: column;

      > :nth-child(2) {
        order: 1;
      }
      > :nth-child(1) {
        order: 2;
      }
    }

    .small-screen-buttons {
      display: none;

      @media (max-width: 1080px) {
        display: block;
      }
    }
    .large-screen-buttons {
      @media (max-width: 1080px) {
        display: none;
      }
    }

    .dashboard {
      max-height: 80vh;
      padding-top: 0px;
      overflow: visible;

      #image-map {
        max-height: 80vh;

        @media (max-width: 1080px) {
          max-height: 70vh;
        }
      }
    }

    @media (max-width: $sm) {
      .dashboard__fit-to-bounds {
        top: 78px;
      }
    }

    @media (max-width: 470px) {
      .dashboard__tag-slider {
        top: 138px;
      }
      .dashboard .leaflet-control-zoom.leaflet-bar.leaflet-control {
        top: auto;
      }
    }

    .place-beacon-description {
      margin-bottom: 20px;
    }

    .highlighted {
      margin-bottom: 20px;
    }

    .dashboard__map-wrapper {
      max-height: 80vh;
    }

    .full-wrapper {
      display: flex;
      justify-content: center;
    }

    .cell {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 180px;
    }

    .table-wrapper {
      width: 90%;
    }

    .el-icon-delete:hover {
      cursor: pointer;
    }

    .changes-made {
      text-align: justify;
      padding-left: 20px;
      padding-right: 20px;
      margin-top: 35px;

      @media (max-width: 1080px) {
        margin-bottom: 20px;
      }

      h3 {
        margin-bottom: 15px;
      }
    }
    .buttons {
      display: flex;
      justify-content: right;
      margin-top: 15px;
    }
  }
}
</style>
