<template>
  <div class="asset-history-table">
    <div class="pagination-wrapper">
      <div class="pagination-wrapper__current">
        {{ pageSize * (currentTablePage - 1) + 1 }} -
        {{
          pagedAssetList.length == 20
            ? pagedAssetList.length * currentTablePage
            : (currentTablePage - 1) * pageSize + pagedAssetList.length
        }}
        {{ $t("labels.of").toLowerCase() }}
        {{ typeOfPagination != "" ? assets : assetList.length }}
      </div>

      <el-pagination
        class="pagination"
        background
        layout="prev, pager, next"
        :page-size="pageSize"
        @current-change="value => (currentTablePage = value)"
        :total="typeOfPagination != '' ? assets : assetList.length"
      >
      </el-pagination>

      <div class="download-assets" @click.stop="downloadAssets()">
        <span>
          {{ $t("labels.export") }}
        </span>
        <span class="download-assets__icon">
          <i class="el-icon-download"></i>
        </span>
      </div>
    </div>

    <el-table
      :data="pagedAssetList"
      class="asset-list-table"
      style="width: 100%"
      :default-sort="{ prop: 'lastUpdate', order: 'descending' }"
      :max-height="windowWidth < 500 ? '60vh' : 700"
    >
      <template slot="empty">
        {{ $t("labels.noData") }}
      </template>
      <el-table-column width="30px">
        <template slot-scope="scope">
          <el-tooltip
            v-if="scope.row.manualEntry"
            :content="$t('tooltip.entryAddedManually')"
            placement="top"
          >
            <span><i class="el-icon-user-solid"></i></span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        :label="
          `${$tc('labels.asset', 1).toUpperCase()} (${$tc(
            'labels.tag',
            1
          ).toUpperCase()})`
        "
        min-width="90"
        sortable
        prop="tagLabel"
      >
        <template slot-scope="scope">
          <template v-if="scope.row.tagLabel">
            {{ scope.row.tagLabel }}
          </template>
          <template v-if="scope.row.upperHex">
            ({{ scope.row.upperHex }})
          </template>
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('labels.status').toUpperCase()"
        min-width="100"
        sortable
        prop="status"
      >
        <template slot-scope="scope">
          <template v-if="scope.row.status">
            {{ scope.row.status }}
          </template>
          <template v-else>
            <hr />
          </template>
        </template>
      </el-table-column>

      <el-table-column
        prop="beaconId"
        :label="$t('labels.unloadLocation').toUpperCase()"
        min-width="100"
        sortable
      >
        <template slot-scope="scope">
          <template v-if="scope.row.beaconLabel">
            {{ scope.row.beaconLabel }}
          </template>
          <template v-if="scope.row.beaconId">
            ({{ toHex(scope.row.beaconId) }})
          </template>
          <template v-else>
            <hr />
          </template>
        </template>
      </el-table-column>

      <!-- <el-table-column
        prop="entryTs"
        label="ENTRY TIME"
        min-width="100"
        sortable
      >
        <template slot-scope="scope">
          <template v-if="scope.row.entryTs">
            {{ getTimeStampTransformed(scope.row.entryTs) }}
          </template>
          <template v-else>
            <hr />
          </template>
        </template>
      </el-table-column> -->

      <el-table-column
        prop="lastUpdate"
        :label="$t('labels.unloadTime').toUpperCase()"
        min-width="110"
        sortable
      >
        <template slot-scope="scope">
          <template v-if="scope.row.lastUpdate">
            {{ getTimeStampTransformed(scope.row.lastUpdate) }}
          </template>
          <template v-else>
            <hr />
          </template>
        </template>
      </el-table-column>

      <el-table-column
        prop="lastBeaconPassedBy"
        :label="$t('labels.passedByLocation').toUpperCase()"
        min-width="110"
        sortable
      >
        <template slot-scope="scope">
          <template v-if="scope.row.passedByBeaconLabel">
            {{ scope.row.passedByBeaconLabel }}
          </template>
          <template v-if="scope.row.passedByBeaconId">
            ({{ toHex(scope.row.passedByBeaconId) }})
          </template>
          <template v-else>
            <hr />
          </template>
        </template>
      </el-table-column>

      <el-table-column
        prop="passedByTs"
        :label="$t('labels.passedByTime').toUpperCase()"
        min-width="110"
        sortable
      >
        <template slot-scope="scope">
          <template v-if="scope.row.passedByTs">
            {{ getTimeStampTransformed(scope.row.passedByTs) }}
          </template>
          <template v-else>
            <hr />
          </template>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapState } from "vuex";

export default {
  name: "AssetHistoryTable",

  props: {
    assetList: {
      type: Array,
      default: () => []
    },
    typeOfPagination: {
      type: String,
      default: ""
    },
    group: {
      default: () => null
    },
    assets: {
      default: () => null
    },
    hasGpsAnchor: {
      default: () => true
    }
  },

  watch: {
    currentTablePage: {
      handler(newValue) {
        switch (this.typeOfPagination) {
          case "group":
            this.$emit("current-table-page", {
              group: this.group,
              page: newValue - 1
            });
            break;
          case "overOneDay":
            this.$emit("current-table-page", {
              recent: false,
              page: newValue - 1
            });
            break;
          case "underOneDay":
            this.$emit("current-table-page", {
              recent: true,
              page: newValue - 1
            });
            break;
          case "travelling":
            this.$emit("current-table-page", newValue - 1);
            break;
          default:
            break;
        }
      }
    },
    assetList: {
      handler(newValue) {
        newValue.forEach(asset => {
          this.resources.data.tags.forEach(tag => {
            if (tag.id == asset.beaconId) {
              asset.beaconId = this.toHex(tag.tagId);
              asset.beaconLabel = tag.label;
            }
          });
        });
      }
    }
  },

  computed: {
    ...mapState("clients", {
      clientData: "data"
    }),
    ...mapState("user", {
      resources: "resources"
    }),
    pagedAssetList() {
      return this.typeOfPagination != ""
        ? this.assetList
        : this.assetList.slice(
            this.pageSize * this.currentTablePage - this.pageSize,
            this.pageSize * this.currentTablePage
          );
    }
  },

  data() {
    return {
      searchTagsOrder: {
        label: true,
        id: true,
        anchorLabel: true,
        lastSeenTs: true,
        battery: true,
        group: true,
        tagType: true
      },
      currentTablePage: 1,
      pageSize: 20,
      pagesRequested: [0],
      windowWidth: window.innerWidth
    };
  },

  methods: {
    toHex(str, type) {
      if (type === "timestamp") return this.getTimeStampTransformed(str);
      else {
        return str
          .toString(16)
          .toUpperCase()
          .match(/.{1,2}/g)
          .join(type === "anchor" ? ":" : "");
      }
    },

    async downloadAssets() {
      const newArray = [];
      this.assetList.forEach(element => {
        newArray.push([
          element.tagLabel,
          element.upperHex,
          element.beaconId ? this.toHex(element.beaconId) : "",
          element.beaconLabel,
          element.lastUpdate,
          element.passedByBeaconId ? this.toHex(element.passedByBeaconId) : "",
          element.passedByBeaconLabel,
          element.passedByTs
        ]);
      }, this);
      let csvContent =
        "Label, Tag ID, Unload Beacon ID, Unload Beacon Label, Unload Time, Passed By Beacon ID, Passed By Beacon label, Passed By Time \n";
      newArray.forEach(function(infoArray) {
        const dataString = infoArray.join(",");
        csvContent += dataString + "\n";
      });
      const anchor = document.createElement("a");
      anchor.href =
        "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent);
      anchor.target = "_blank";
      anchor.download = "tag-list.csv";
      anchor.click();
    },

    getTimeStampTransformed(timestamp) {
      if (timestamp)
        return moment
          .tz(moment.unix(timestamp), this.clientData.timezone)
          .format("lll");
      return "N/A";
    },

    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    }
  },

  mounted() {
    window.addEventListener("resize", this.updateWindowWidth);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.updateWindowWidth);
  }
};
</script>

<style lang="scss">
.pagination-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 500px) {
    flex-direction: column;
    gap: 10px;
  }

  &__current {
    height: 32px;
    display: flex;
    align-items: center;
  }

  .download-assets {
    position: absolute;
    right: 1%;
    display: flex;
    align-items: center;

    @media (max-width: 500px) {
      position: relative;
    }

    &:hover {
      cursor: pointer;
    }

    &__icon {
      margin-left: 5px;
    }
  }
}

// .asset-history-table {
//   .el-table {
//     max-height: 700px;
//     overflow-y: scroll;

//     @media (max-width: 500px) {
//       max-height: 60vh;
//     }
//   }
// }

.cell {
  .el-button--mini {
    padding: 5px;
  }
}

.asset-list-table {
  margin-top: 5px;
}
</style>
