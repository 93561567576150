<template>
  <div class="tag-scanner">
    <div class="tag-scanner__container">
      <div class="tag-scanner__input-section">
        <el-input
          class="tag-scanner__input"
          v-model="scannedTagId"
          clearable
          @input="isValidId"
          @clear="isValidId"
          :placeholder="$t('title.scanTagBeacon')"
          ref="scanInput"
        ></el-input>
      </div>
      <div class="tag-scanner__not-found" v-if="tagIdNotFound.length > 8">
        <span> {{ $t("labels.invalidId") }} </span>
      </div>
      <div class="tag-scanner__not-found" v-else-if="tagIdNotFound.length">
        <span> Id "{{ tagIdNotFound }}" {{ $t("labels.notFound") }} </span>
      </div>
      <div class="tag-scanner__details-section" v-if="tagInformation">
        <div
          class="tag-scanner__warning-container yellow"
          v-if="tagInformation.type === 1 && !tagInformation.active"
        >
          <i class="el-icon-warning" />
          <span>{{ $t("messages.tagInactive") }}</span>
        </div>
        <div
          class="tag-scanner__warning-container red"
          v-else-if="
            tagInformation.type === 0 && tagInformation.active === false
          "
        >
          <i class="el-icon-warning" />
          <span>{{ $t("messages.installBeaconToEdit") }}.</span>
        </div>
        <div class="tag-scanner__info" v-if="tagInformation">
          <div class="close-button" @click="cancelEdit()">
            <i class="el-icon-close"></i>
          </div>
          <div class="tag-scanner__info-detail-not-editable">
            <div class="flex-column">
              <div class="flex-row">
                <template v-if="tagInformation.type === 1">{{
                  $tc("labels.tag", 1)
                }}</template>
                <template v-else-if="tagInformation.type === 0">{{
                  $tc("labels.beacon", 1)
                }}</template>
                ({{ hexTagId }})
                <TempBatteryIcon :battery="tagInformation.battery" />
              </div>
              <div v-if="tagInformation.active">
                {{ formattedLastUpdate }}
              </div>
            </div>
          </div>
          <div
            :class="[
              'tag-scanner__info-detail',
              {
                'red-text': !tagInformation.active && tagInformation.type === 0
              }
            ]"
          >
            <span class="tag-scanner__info-label"
              >{{ $t("labels.installed") }}:</span
            >
            <span
              class="tag-scanner__info-value"
              v-if="tagInformation.type === 1"
            >
              <el-switch
                v-model="activeInput"
                :active-text="$t('confirmation.yes')"
                :inactive-text="$t('confirmation.no')"
                @change="onTagActiveStatusChange()"
              >
              </el-switch>
            </span>
            <div class="tag-scanner__active-state" v-else>
              <span class="tag-scanner__info-value">{{
                tagInformation.active === true
                  ? $t("confirmation.yes")
                  : $t("confirmation.no")
              }}</span>
              <el-button
                v-if="tagInformation.active === true"
                size="mini"
                @click="onBeaconActiveStatusChange(false)"
              >
                {{ $t("actions.removeFromMap") }}
              </el-button>
            </div>
          </div>
          <div class="tag-scanner__info-detail">
            <span class="tag-scanner__info-label"
              >{{ $t("labels.label") }}:</span
            >
            <div class="tag-scanner__actions">
              <span
                class="tag-scanner__info-value"
                v-if="!isActiveBeaconOrTag"
                >{{ tagInformation.label }}</span
              >
              <el-input
                v-model="labelToEdit"
                clearable
                v-if="isActiveBeaconOrTag"
                ref="labelInput"
                class="scanner-input"
              ></el-input>
            </div>
          </div>
          <div class="tag-scanner__info-detail">
            <span class="tag-scanner__info-label"
              >{{ $t("labels.status") }}:</span
            >
            <div class="tag-scanner__actions">
              <span
                class="tag-scanner__info-value"
                v-if="!isActiveBeaconOrTag"
                >{{ tagInformation.status }}</span
              >
              <el-select
                v-model="statusToEdit"
                :placeholder="$t('placeholder.selectStatus')"
                filterable
                v-if="isActiveBeaconOrTag"
                class="scanner-input"
                ref="statusInput"
              >
                <el-option
                  v-for="status in statusOptions"
                  :key="`tag.status-${status}`"
                  :label="status"
                  :value="status"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div
            class="tag-scanner__info-detail"
            v-if="tagInformation.type === 1"
          >
            <span class="tag-scanner__info-label"
              >{{ $t("labels.associatedLocation") }}:</span
            >
            <div class="tag-scanner__actions">
              <span class="tag-scanner__info-value" v-if="!isActiveBeaconOrTag">
                {{ toHex(tagInformation.beaconId) ?? "-" }}
              </span>
              <el-select
                v-if="isActiveBeaconOrTag"
                v-model="associatedBeaconId"
                :placeholder="$t('placeholder.selectBeacon')"
                filterable
                ref="beaconInput"
                class="scanner-input"
              >
                <el-option
                  :key="'clear-option-beacon'"
                  :label="null"
                  :value="null"
                  v-if="activeBeacons.length || tagInformation.beaconId"
                  ><b>{{ $t("actions.remove").toUpperCase() }}</b></el-option
                >
                <el-option
                  v-for="tag in activeBeacons"
                  :key="`tag.beacon${tag.id}`"
                  :label="tag.tagId ? `${tag.label} (${toHex(tag.tagId)})` : ''"
                  :value="toHex(tag.tagId)"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="tag-scanner__info-detail" v-if="tagInformation.active">
            <span class="tag-scanner__info-label"
              >{{ $t("dialogs.defaultEdit.replace") }}:</span
            >
            <div class="tag-scanner__actions">
              <el-select
                v-model="tagToReplaceId"
                :placeholder="
                  tagInformation.type === 0
                    ? $t('placeholder.selectBeacon')
                    : $tc('placeholder.selectTag', 1)
                "
                filterable
                class="scanner-input"
                clearable
              >
                <el-option
                  :key="'clear-option-replace'"
                  :label="null"
                  :value="null"
                  v-if="inactiveOptions.length"
                  ><b>{{ $t("actions.remove").toUpperCase() }}</b></el-option
                >
                <el-option
                  v-for="tag in inactiveOptions"
                  :key="`tag.replace${tag.id}`"
                  :label="tag.tagId ? `${tag.label} (${toHex(tag.tagId)})` : ''"
                  :value="tag.id"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div v-if="tagInformation" class="tag-scanner__buttons">
            <el-button
              @click="onBeaconActiveStatusChange(true)"
              icon="el-icon-location-outline"
              v-if="!isActiveBeaconOrTag && tagInformation.type === 0"
              style="width: 100%;"
            >
              {{ $t("actions.addOnMap") }}
            </el-button>
            <el-button
              @click="cancelEdit()"
              class="tag-scanner__custom-button"
              v-if="
                tagInformation.type === 1 ||
                  (isActiveBeaconOrTag && tagInformation.type === 0)
              "
              >{{ $t("button.cancel") }}</el-button
            >
            <el-button
              :disabled="isSubmitDisabled"
              v-if="
                tagInformation.type === 1 ||
                  (isActiveBeaconOrTag && tagInformation.type === 0)
              "
              class="tag-scanner__submit-button tag-scanner__custom-button"
              @click="updateTag(tagInformation.tagId, labelToEdit)"
              ref="submitButton"
              >{{ $t("button.submit") }}</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      top="25vh"
      class="tag-scanner__dialog"
      :title="$t('dialogs.activateTag.title')"
      :visible.sync="isDialogVisible.removeFromMap"
      :close-on-click-modal="false"
      @closed="activeInput = tagInformation ? tagInformation.active : false"
      v-if="tagInformation"
    >
      <span class="warning"
        >{{ $t("dialogs.activateTag.beaconRemoveWarning") }}<br />
      </span>
      <span slot="footer" class="tag-scanner__dialog-footer">
        <el-button
          @click="isDialogVisible.removeFromMap = false"
          size="small"
          class="tag-scanner__footer-button"
          >{{ $t("button.cancel") }}</el-button
        >
        <el-button
          type="primary"
          @click="updateTag(tagInformation.tagId, labelToEdit, true)"
          size="small"
          class="tag-scanner__submit-button tag-scanner__footer-button"
          >{{ $t("button.save") }}</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      top="2vh"
      class="tag-scanner__dialog"
      :title="$t('dialogs.activateTag.title')"
      :visible.sync="isDialogVisible.addOnMap"
      :close-on-click-modal="false"
      @closed="activeInput = tagInformation ? tagInformation.active : false"
      v-if="tagInformation && isDialogVisible.addOnMap"
    >
      <span>
        <div
          v-if="highlightedBeacon?.tagId"
          class="highlighted-beacon-container"
        >
          <span class="highlighted-beacon">
            <b>{{ $tc("labels.beacon", 1) }}:</b>
            {{ highlightedBeacon.label }} ({{ toHex(highlightedBeacon.tagId) }})
          </span>
        </div>
        <LiveMap
          :is-scanner-map="true"
          :beacon-to-edit="beaconToEdit"
          @update-highlighted-beacon="updateHighlightedBeacon"
          @update-beacon-on-map="updateBeaconLocation"
        />
      </span>
      <span slot="footer" class="tag-scanner__dialog-footer">
        <el-button
          @click="isDialogVisible.addOnMap = false"
          size="small"
          class="tag-scanner__footer-button"
          >{{ $t("button.cancel") }}</el-button
        >
        <el-button
          type="primary"
          @click="updateBeaconPosition(beaconToEdit)"
          size="small"
          class="tag-scanner__submit-button tag-scanner__footer-button"
          >{{ $t("button.save") }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import positionLogsApi from "@/modules/position-logs/api/position-logs.api";
import tagsApi from "@/modules/tags/api/tags.api";
import moment from "moment-timezone";
import { mapActions, mapState } from "vuex";
import * as actionTypes from "@/store/action-types";
import TempBatteryIcon from "../icons/TempBatteryIcon.vue";
import LiveMap from "@/views/LiveMap.vue";

export default {
  name: "TagScanner",
  components: {
    TempBatteryIcon,
    LiveMap
  },
  data() {
    return {
      isDialogVisible: {
        editLabelDialog: false,
        editActiveDialog: false,
        removeFromMap: false,
        addOnMap: false
      },
      isTagAvailableToReplace: false,
      labelToEdit: "",
      scannedTagId: "",
      statusToEdit: "",
      statusOptions: ["NO STATUS", "NOK", "OK"],
      tagIdNotFound: "",
      tagInformation: null,
      tags: [],
      tagToScan: "",
      validInput: false,
      warningInput: false,
      tagList: [],
      activeInput: false,
      associatedBeaconId: null,
      tagToReplaceId: null,
      beaconToEdit: null,
      highlightedBeacon: {},
      updatedBeaconOnMap: {},
      isScanning: false,
      scanTimeout: null
    };
  },
  computed: {
    ...mapState("clients", {
      clientData: "data"
    }),
    ...mapState("user", {
      resources: "resources"
    }),
    formattedLastUpdate() {
      if (this.tagInformation && this.tagInformation.lastUpdate) {
        return moment
          .tz(
            moment.unix(this.tagInformation.lastUpdate),
            this.clientData.timezone
          )
          .format("lll");
      } else {
        return "";
      }
    },
    lastUpdateMoment() {
      if (this.tagInformation && this.tagInformation.lastUpdate) {
        return moment.tz(
          moment.unix(this.tagInformation.lastUpdate),
          this.clientData.timezone
        );
      } else {
        return null;
      }
    },
    isBatteryLow() {
      return this.tagInformation && this.tagInformation.battery == 0;
    },
    isRecentTag() {
      const oneWeekBeforeToday = moment().subtract(1, "week");
      return this.lastUpdateMoment
        ? this.lastUpdateMoment.isAfter(oneWeekBeforeToday)
        : true;
    },
    hexTagId() {
      return this.tagInformation && this.tagInformation.tagId
        ? this.toHex(this.tagInformation.tagId)
        : null;
    },
    inactiveOptions() {
      return this.tagInformation
        ? this.tagInformation.type === 0
          ? this.tagList.filter(tag => tag.type === 0 && tag.active === false)
          : this.tagList.filter(tag => tag.type === 1 && tag.active === false)
        : [];
    },
    activeBeacons() {
      return this.tagInformation
        ? this.tagList.filter(tag => tag.type === 0 && tag.active === true)
        : [];
    },
    // Returns true if it is a beacon and active or if it's a tag
    isActiveBeaconOrTag() {
      return this.tagInformation?.type === 0
        ? this.tagInformation.active
        : true;
    },
    isSubmitDisabled() {
      return (
        this.labelToEdit == this.tagInformation.label &&
        this.statusToEdit == this.tagInformation.status &&
        this.associatedBeaconId == this.toHex(this.tagInformation.beaconId) &&
        !this.tagToReplaceId
      );
    }
  },
  watch: {
    tagInformation(newValue) {
      if (newValue) {
        this.labelToEdit = newValue.label;
        this.activeInput = newValue.active;
        this.statusToEdit = newValue.status;
        this.associatedBeaconId = newValue.beaconId
          ? this.toHex(newValue.beaconId)
          : null;
      } else {
        this.labelToEdit = "";
        this.activeInput = false;
        this.associatedBeaconId = null;
        this.statusToEdit = "NO STATUS";
      }
    },
    labelToEdit(newValue) {
      if (this.tagInformation && this.tagInformation.type === 1) {
        this.activeInput = Boolean(newValue);
      }
    },
    activeInput(newValue) {
      if (this.tagInformation && this.tagInformation.type === 1) {
        if (newValue) {
          if (!this.labelToEdit) {
            this.labelToEdit = this.hexTagId;
          }
        } else {
          this.labelToEdit = "";
        }
      }
    }
  },
  methods: {
    ...mapActions("user", {
      userSetResourcesTags: actionTypes.USER_SET_RESOURCES_TAGS
    }),
    // Searches tag information
    searchTag(tagId) {
      this.isScanning = true;
      const foundTag = this.tagList.find(
        tag => this.toHex(tag.tagId) == tagId.toUpperCase()
      );
      if (foundTag) {
        this.tagInformation = foundTag;
        this.tagToReplaceId = null;
        this.scannedTagId = "";
        clearTimeout(this.scanTimeout);
        this.scanTimeout = setTimeout(() => {
          this.isScanning = false; // Reset scanning flag after timeout
          this.$refs?.scanInput?.blur();
        }, 500);
        this.$nextTick(() => {
          const inputElement = this.$refs.labelInput?.$el?.querySelector(
            "input"
          );
          if (inputElement) {
            inputElement.focus();
            inputElement.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        });
      } else {
        this.tagInformation = null;
        this.tagIdNotFound = this.scannedTagId;
        this.scannedTagId = "";
        this.isScanning = false;
        this.$refs?.scanInput?.blur();
      }
    },
    // Validates the scanned ID; if valid, calls search tag method
    isValidId(tagId) {
      this.tagIdNotFound = "";
      const isValidTag = tagId?.length === 8;

      if (!isValidTag) {
        this.tagInformation = null;
        this.tagIdNotFound = tagId?.length > 8 ? tagId : "";
      } else {
        this.searchTag(tagId);
      }
    },
    // Replaces tag
    async replaceTag(tagInfo) {
      try {
        const lastPosition = tagInfo.lastPosition;
        await positionLogsApi.updateTagPosition(tagInfo.id, {
          tagId: this.tagToReplaceId,
          x: lastPosition ? lastPosition.x : null,
          y: lastPosition ? lastPosition.y : null
        });
        this.tagToReplaceId = null;
      } catch (err) {
        throw new Error("Replace tag operation failed");
      }
    },
    // Updates beacon/tag information
    async updateTag(tagId, label, removeFromMap = false) {
      try {
        if (this.tagToReplaceId) {
          await this.replaceTag(this.tagInformation);
        }

        let res;
        if (this.tagInformation.type === 0) {
          // Beacons
          console.log("here i am beacon");
          const active = removeFromMap ? false : this.tagInformation.active;
          res = await tagsApi.updateTag(tagId, {
            label: label,
            active: active,
            childBeacons: [],
            beaconsToRemove: [],
            status: active ? this.statusToEdit : "NO STATUS"
          });
        } else if (this.tagInformation.type === 1) {
          console.log("here i am");
          // Tags
          const foundTag = this.tagList.find(
            tag => this.toHex(tag.tagId) == this.associatedBeaconId
          );
          let beaconId = null;
          if (!this.associatedBeaconId || (foundTag && foundTag.type === 0)) {
            beaconId = !this.associatedBeaconId ? null : foundTag.id;
            console.log("here");
            res = await tagsApi.updateTag(tagId, {
              label: label,
              active: this.activeInput,
              childBeacons: [],
              beaconsToRemove: [],
              beaconId: beaconId,
              status: this.activeInput ? this.statusToEdit : "NO STATUS"
            });
          } else {
            this.$notify.error({
              title: "Validation Error",
              message: this.$t("messages.invalidBeaconId")
            });
            return;
          }
        }
        if (res.data) {
          this.isDialogVisible.editActiveDialog = false;
          this.isDialogVisible.removeFromMap = false;
          this.$notify({
            title: this.$t("statusMessages.success"),
            message:
              this.tagInformation.type === 0
                ? this.$t("messages.beaconUpdated")
                : this.$t("messages.tagUpdated"),
            type: "success"
          });
          this.tagInformation.label = res.data.label;
          this.tagInformation.active = res.data.active;
          this.activeInput = res.data.active;
          this.tagInformation.beaconId = res.data.beaconId;
          this.tagInformation.status = res.data.status;
          this.getTagList();
        }
      } catch (error) {
        this.$notify.error({
          title: this.$t("statusMessages.error"),
          message: error.message.includes("Replace tag operation failed")
            ? this.$t("messages.replaceError")
            : this.$t("messages.genericError")
        });
      }
    },
    // Updates beacon position on map and updates the displayed beacon information
    async updateBeaconPosition(beaconInfo) {
      try {
        const res = await positionLogsApi.createTags([
          {
            tagId: beaconInfo.id,
            x: beaconInfo.lastPosition.x,
            y: beaconInfo.lastPosition.y
          }
        ]);
        if (res.data) {
          this.tagInformation.lastPosition = {
            x: res.data.x,
            y: res.data.y
          };
          this.tagInformation.seenAt = res.data.seenAt;
          this.tagInformation.createdDate = res.data.createdDate;
          this.tagInformation.active = true;
          this.isDialogVisible.addOnMap = false;
          this.getTagList();
          this.$notify({
            title: this.$t("statusMessages.success"),
            message:
              this.tagInformation.type === 0
                ? this.$t("messages.beaconUpdated")
                : this.$t("messages.tagUpdated"),
            type: "success"
          });
        }
      } catch (err) {
        this.$notify.error({
          title: this.$t("statusMessages.error"),
          message: this.$t("messages.genericError")
        });
      }
    },
    // Handles key board events
    handleKeyPress(e) {
      const labelInput = this.$refs.labelInput?.$el?.querySelector("input");
      const inputs = Array.from(
        document.querySelectorAll(".scanner-input input ")
      );
      const allBlured = inputs.every(input => input !== document.activeElement);
      const currentIndex = inputs.indexOf(document.activeElement);

      // Block the enter behavior right after scanning
      if (this.isScanning && labelInput === document.activeElement) {
        if (e.key === "Enter" || e.code === "Enter") {
          e.preventDefault();
        }
        return;
      }

      // Focus the main scan input if it's not already focused or if tag information is missing
      if (allBlured || !this.tagInformation) {
        this.$refs?.scanInput?.focus();
      } else if (
        currentIndex !== -1 &&
        currentIndex < inputs.length - 1 &&
        (e.key === "Enter" || e.code === "Enter")
      ) {
        // Move focus to the next input on 'Enter' key press
        const elSelect = inputs[currentIndex].closest(".el-select");
        if (elSelect) {
          const selectComponent = elSelect.__vue__;
          if (selectComponent) {
            selectComponent.visible = false;
          }
        }
        inputs[currentIndex + 1].focus();
      } else if (
        currentIndex !== -1 &&
        currentIndex === inputs.length - 1 &&
        (e.key === "Enter" || e.code === "Enter")
      ) {
        const elSelect = inputs[currentIndex].closest(".el-select");
        if (elSelect) {
          const selectComponent = elSelect.__vue__;
          if (selectComponent) {
            selectComponent.visible = false;
          }
        }
        inputs[currentIndex].blur();
        this.$refs?.submitButton?.$el?.click();
      }
    },
    toHex(str) {
      return str
        ? str
            .toString(16)
            .toUpperCase()
            .match(/.{1,2}/g)
            .join("")
        : null;
    },
    // Gets list of tags and updates the resources tags based on the result
    async getTagList() {
      try {
        const res = await tagsApi.getRefreshStatus();
        const newData = res.data;
        this.tagList = newData;

        let tagListToUpdate = this.resources.data.tags;
        tagListToUpdate = tagListToUpdate.map(existingTag => {
          const updatedTag = newData.find(
            newTag => newTag.id === existingTag.id
          );
          return updatedTag ? { ...existingTag, ...updatedTag } : existingTag;
        });

        this.userSetResourcesTags(tagListToUpdate);
      } catch (error) {
        this.$notify.error({
          title: this.$t("statusMessages.error"),
          message: this.$t("messages.genericError")
        });
      }
    },
    onTagActiveStatusChange() {
      if (this.tagInformation.active !== this.activeInput) {
        this.isDialogVisible.editActiveDialog = true;
      }
    },
    onBeaconActiveStatusChange(boolean) {
      this.activeInput = boolean;
      if (this.activeInput === true) {
        this.beaconToEdit = this.tagInformation;
        this.isDialogVisible.addOnMap = true;
      } else {
        this.isDialogVisible.removeFromMap = true;
      }
    },
    updateHighlightedBeacon(beaconData) {
      this.highlightedBeacon = beaconData;
    },
    updateBeaconLocation(beaconData) {
      this.updatedBeaconOnMap = beaconData;
    },
    cancelEdit() {
      this.tagInformation = null;
    }
  },
  mounted() {
    document.addEventListener("keydown", this.handleKeyPress);
  },

  beforeUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress);
  },

  created() {
    this.getTagList();
  }
};
</script>

<style lang="scss">
.tag-scanner {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  font-size: 16px;
  min-height: 60vh;

  &__display-error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 100%;

    @media (max-width: $sm) {
      display: none;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    height: 100%;
    padding: 40px 10px 30px;
    width: 400px;

    @media (max-width: 380px) {
      padding-top: 5px;
    }

    @media (max-width: 500px) {
      gap: 20px;
      width: 100%;
    }
  }

  &__input-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .el-switch__label {
    font-size: 16px !important;
  }
  &__dialog {
    .el-dialog {
      width: 400px;

      @media (max-width: $xs) {
        width: 95%;
      }
    }

    .el-dialog__title {
      font-size: 16px;

      @media (max-width: $xs) {
        font-size: 20px;
      }
    }
    .el-dialog__body {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding-bottom: 5px;
      word-break: auto-phrase;
      font-size: 16px;

      @media (max-width: $xs) {
        font-size: 20px;
      }
    }
    .el-select .el-input--suffix {
      width: 100%;
      font-size: 16px;

      @media (max-width: $xs) {
        font-size: 20px;
      }
    }

    .highlighted-beacon-container {
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      font-size: 16px;
    }
  }

  .dashboard__map.leaflet-container {
    height: 50vh;

    @media (max-width: $xs) {
      width: 100%;
    }
  }

  .dashboard__fit-to-bounds-settings {
    position: absolute;
    top: 85px;

    @media (max-width: 470px) {
      top: 85px;
    }
  }

  .dashboard {
    padding-top: 0;

    @media (max-width: 1024px) {
      padding-top: 0;
    }
  }

  .dashboard .leaflet-control-zoom.leaflet-bar.leaflet-control {
    @media (max-width: 470px) {
      top: 0;
    }
  }

  .dashboard__tag-slider-settings {
    top: 130px;
    @media (max-width: 768px) {
      top: 130px;
    }
  }

  &__dialog-footer {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
  }

  &__footer-button {
    height: 65px !important;
    font-size: 16px !important;

    @media (max-width: $xs) {
      font-size: 18px !important;
    }

    @media (max-width: 380px) {
      height: 60px !important;
    }
  }

  &__buttons {
    display: flex;
    gap: 5px;
    width: 100%;
    padding-top: 20px;
  }

  &__custom-button {
    height: auto;
    font-size: 14px;
    width: 50% !important;

    @media (max-width: $xs) {
      font-size: 18px;
      height: 65px;
    }

    @media (max-width: 380px) {
      height: 60px;
    }
  }

  &__submit-button {
    background-color: #1f4258;
    color: #ffffff;
    margin-left: 0 !important;
  }

  &__input {
    width: 100%;
  }

  &__not-found {
    height: 100%;
    color: red;
    font-weight: bold;
    padding-top: 20px;
  }

  &__details-section {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (max-width: 380px) {
      gap: 20px;
    }
  }

  &__id-display {
    font-size: 24px;
    font-weight: bold;
  }

  &__id {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    position: relative;

    .close-button {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
    }
  }

  &__info-detail {
    display: grid;
    align-items: center;
    justify-items: left;
    grid-template-columns: 40% 60%;
    gap: 5px;
    width: 100%;
  }

  &__inactive-state {
    display: flex;
    justify-content: center;
    align-self: center;
    height: 400px;
  }

  &__inactive-state.red-text {
    font-weight: bold;
    color: #e50000;
  }

  &__info-detail.red-text {
    font-weight: bold;
    color: #e50000;
  }

  &__info-label {
    text-align: left;
    font-size: 16px;
  }

  &__info-value {
    font-weight: bold;
    white-space: nowrap;
    text-align: left;
  }

  &__info-detail-not-editable {
    font-weight: bold;
    white-space: nowrap;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
    padding: 8px 0 8px;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    white-space: nowrap;
    gap: 2px;

    .flex-row {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 20px;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;

    .tag-scanner__info-value {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px;
      min-width: 30px;
    }
    .el-select {
      width: 100% !important;
    }
  }
  &__active-state {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;

    .tag-scanner__info-value {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px;
      min-width: 30px;
    }
    .el-button {
      width: 80%;

      @media (max-width: $sm) {
        padding: 10px 2px;
      }
    }
  }

  &__warning-container {
    display: flex;
    align-items: center;
    padding: 8px;
    gap: 8px;
    border-radius: 5px;
    font-size: 16px;
    text-align: justify;

    @media (max-width: $sm) {
      font-size: 14px;
    }
  }

  .yellow {
    background-color: #fff7cc;
    border: 1px solid #ffeb7f;
  }

  .red {
    background-color: #ffcccc;
    border: 1px solid #ff7f7f;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 100%;
    height: auto;
    font-size: 16px;
    background-color: #1f4258;
    color: #ffffff;
    margin-left: 0 !important;

    &:hover,
    &:active,
    &:focus {
      background-color: #5d707d !important;
      color: #ffffff !important;
      border: none;
    }

    @media (max-width: 380px) {
      font-size: 18px;
      height: 65px;
    }

    @media (max-width: $xs) {
      font-size: 20px;
      height: 70px;
    }
  }
  &__button.red-button {
    background-color: #e50000;

    &:focus {
      background-color: #ff6f61 !important;
    }
  }

  &__button-info {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
</style>
