<script>
import { Line } from "vue-chartjs";
import moment from "moment";

export default {
  extends: Line,
  props: {
    datasets: {
      type: Array,
      required: false
    },
    labels: {
      type: Array,
      required: true
    },
    yLabel: {
      type: String
    },
    xLabel: {
      type: String
    }
  },
  data() {
    return {
      labelString: this.yLabel,
      type: "line",
      yAxesValues: [],
      chartdata: {
        labels: [],
        datasets: []
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true
        },
        tooltips: {
          callbacks: {
            label: tooltipItem => this.formatTooltipValue(tooltipItem.value)
          }
        },
        scales: {
          yAxes: [
            {
              display: true,
              stacked: false,
              scaleLabel: {
                display: true,
                labelString: this.yLabel
              },
              ticks: {
                min: 0,
                precision: 0,
                stepSize: 0,
                callback: (value, idx) => this.formatLabelYAxes(value, idx)
              }
            }
          ],
          xAxes: [
            {
              display: true,
              stacked: true,
              gridLines: {
                display: false
              },
              scaleLabel: {
                display: true,
                labelString: this.xLabel
              }
            }
          ]
        }
      }
    };
  },
  watch: {
    datasets: {
      deep: true,
      handler() {
        this.render();
      }
    },
    labels: {
      deep: true,
      handler() {
        this.render();
      }
    },
    "$i18n.locale": {
      handler() {
        this.updateChartLabels();
      }
    }
  },
  methods: {
    formatTooltipValue(time) {
      if (this.xLabel == this.$t("analytics.weekday")) {
        if (time > 0) {
          return moment.utc(time * 3600000).format("HH[h]mm[m]ss[s]");
        } else {
          return moment.utc(time * 3600000).format("mm[m]ss[s]");
        }
      } else {
        const duration = moment.duration(time * 1000);
        const hours = duration.hours();
        if (hours > 0) {
          return moment.utc(time * 1000).format("HH[h]mm[m]ss[s]");
        } else {
          return moment.utc(time * 1000).format("mm[m]ss[s]");
        }
      }
    },
    render() {
      this.chartdata.datasets = this.datasets.map(dataset => ({
        label: dataset.label,
        borderColor: dataset.borderColor || "#1F4258",
        pointBackgroundColor: dataset.pointBackgroundColor || "#1F4258",
        backgroundColor: "transparent",
        fill: false,
        data: dataset.data,
        tension: 0
      }));
      this.chartdata.labels = this.labels;
      this.renderChart(this.chartdata, this.options);
    },
    updateChartLabels() {
      //// https://jsfiddle.net/leighking2/1vy6hdjk/ TENTAR ISTO
      if (this.xLabel == this.$t("analytics.weekday")) {
        this.options.scales.yAxes[0].scaleLabel.labelString = this.$t(
          "labels.timeHourFormat"
        );
      } else {
        this.options.scales.yAxes[0].scaleLabel.labelString =
          this.yLabel || this.$t("labels.timeMinFormat");
      }
      this.options.scales.xAxes[0].scaleLabel.labelString = this.xLabel;
      this.render();
    },
    formatLabelYAxes(time) {
      if (this.xLabel == this.$t("analytics.weekday")) {
        return Math.floor(time * 2) / 2;
      } else return Math.ceil(time / 60);
    }
  },
  mounted() {
    if (!this.yLabel) {
      this.labelString =
        this.xLabel == this.$t("analytics.weekday")
          ? this.$t("labels.timeHourFormat")
          : this.$t("labels.timeMinFormat");
    } else {
      this.labelString = this.yLabel;
    }
    this.options.scales.yAxes[0].scaleLabel.labelString = this.labelString;

    this.render();
  }
};
</script>
